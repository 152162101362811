import React from "react";

import "antd/dist/antd.css";

import { Modal, Button, Space, Input } from "antd";

class AlertBox extends React.Component {
  constructor(props) {
    super(props);
  }
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (e) => {
    let res = await this.props.callback();
    let refresh=this.props.refresh
    
    if (res && res == "success") {
      this.setState({
        visible: false,
      });
    }
  };

  handleCancel = (e) => {
    
    
    
    this.props.password&&(window.location.pathname=(this.props.refresh))
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <Button disabled={this.props.desabled&&this.props.desabled}
          style={{ backgroundColor: "#401A0A", color: "white" }}
          onClick={this.showModal}
        >
          <Space>
            <span className="">{this.props.button}</span>
            <span>{this.props.spin}</span>
          </Space>
        </Button>
        <Modal
          title={<div style={{ color: "#401A0A" }}>{this.props.title}</div>}
          visible={this.state.visible}
          secondary
          footer={null}
          //  onOk={this.handleOk,<div style={{backgroundColor:"red"}}></div>}
          onCancel={this.handleCancel}
        >
          <div>
            {this.props.password}
           <p style={{margin:"2%"}}> {this.props.content1}</p>
            {this.props.content && (
              <p
                className=""
                style={ this.props.content&&( 
                     {
                        backgroundColor: "orange",
                        color: "white",
                        fontSize: "16px",
                        padding: "3%",
                        // borderRadius: "3%",
                      }
                )
                }
              >
                {this.props.content}
              </p>
            ) }
            <p>{this.props.comment}</p>
            <div style={{marginTop:"2%"}}>
            <span>{this.props.checkbox}</span>{" "}
            <span style={{ color: "#401A0A" }}>{this.props.queation}</span>
            </div>
            {this.props.checkbox&& (
              <>
                <hr />
                <p
                  style={{
                    fontSize: "16px",
                    color: "#401A0A",
                    padding: "4%",
                    fontFamily: "cursive",
                  }}
                >
                  {this.props.checkBoxbelow}
                </p>
              </>
            ) }
          </div>
          <button
            className="btn btn-secondary "
            style={{ marginTop: "2%" }}
            onClick={this.handleCancel}
          >
            Cancel
          </button>
          <button
            className="btn   pull-right "
            style={{
              width: "64px",
              backgroundColor: "#401A0A",
              color: "white",
              marginTop: "2%",
            }}
            onClick={this.handleOk}
          >
            Ok
          </button>
        </Modal>
      </div>
    );
  }
}

export default AlertBox;
// export default  antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
