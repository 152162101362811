import React, { Component } from "react";
import InnerBanner from "./components/common/innerBanner";
import Breadcrumbs from "./components/common/breadcrumbs";
import { Redirect } from "react-router-dom";



 
import ProfileService from "./services/ProfileService";
import ListingService from "./services/ListingService";
import UploadPic from "./components/common/uploadPic";
import TokenExpired from "./components/common/tokenExpired";

import "antd/dist/antd.css";
import {Avatar,Dropdown,Menu,Button,message,Space, DatePicker, Row, Select, Steps, } from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import * as constant from ".//constants";

import AlertBox from "./components/common/Notification";
import Moment from "react-moment";
import moment from "moment"
import 'moment/locale/zh-cn';


class ProfileEdit extends Component {
  constructor() {
    super();

    this.form = React.createRef();
    this.state = {
      profileImage: null,
      // dateOf:today,
      horoscopeImage: "",
      photo1Image: "",
      photo2Image: "",
      profileFor: "",
      profileNumber: "NA",
      profileId: "",
      firstName: "",
      lastName: "",
      gender: "",
      height: "",
      dob:"",
      maritalStatus: "",
      motherName: "",
      motherAge: "",
      motherOccupation: "",
      fatherName: "",
      fatherAge: "",
      fatherOccupation: "",
      familyIncome: "",
      familyValues: "",
      city: "",
      statee: "",
      highestEducation: "",
      ugDegree: "",
      ugCollege: "",
      pgDegree: "",
      pgCollege: "",
      occupation: "",
      income: "",
      desiredAgeGroup: "",
      desiredHeight: "",
      
      desiredMaritalStatus: "",
      message: "",
      submitted: false,
      fadeIn: false,
      redirect: null,
      btnDisplay: "none",
      activationStatus: "INACTIVE",
      submitSource: "saveProfile",
      caste: "",
      gotra: "",
      nakshatra: "",
      rasi: "",
      desiredCaste: "",
      desiredGotra: "",
      desiredGender: "",
      toggole: true,
      PlaceOfBirth: "",
      desiredPlaceOfBirth: "",
      visible: false,
      country: "",
      cast: "",
      cast1: "",
      desiredCaste1: "",
      feet: "",
      inch: "",
      date: "",
      gotraList:"",
      ageList:[],
    };

    this.validate = this.validate.bind(this);
    this.validate1 = this.validate1.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.GetCountriesList = this.GetCountriesList.bind(this);
    this.GetCasteList = this.GetCasteList.bind(this);
    this.GetGotralist=this.GetGotralist.bind(this);
    this.Getagelist=this.Getagelist.bind(this)
    // this.handleDateChange=this.handleDateChange.bind(this)

  }

  // handleDateChange=(e)=>{
  //   console.log(e,"date")
  // this.setState({dob:e})
  // }
   
  handleChangeslect = (e) => {
    this.setState({ caste: e == "Other Specify" ? "Other Specify" : e });
  };
  handleDesiredCaste = (e) => {
    
    this.setState({desiredCaste: e.toString(","),});
  };
  handleChange(e) {
    this.setState({ desiredMaritalStatus: e.toString("") });
  }
  handleProfileImageChange = (profileImage) => {
    this.setState({ profileImage: profileImage });
  };

  handleHoroscopeImageChange = (horoscopeImage) => {
    this.setState({ horoscopeImage: horoscopeImage, btnDisplay: "block" });
  };

  handlePhotosChange = (photos) => {
    this.setState({
      photo1Image: photos?.[0],
      photo2Image: photos?.[1] ? photos[1] : "",
    });
   
  };
 
  saveChanges() {
    this.setState({ submitted: true });

    let actionRequired =
      sessionStorage.getItem("status") == "ACTIVE" &&
      sessionStorage.getItem("profileStatus") == "ACTIVE" &&
      localStorage.getItem("profileInactiveDays") > 15 &&
      localStorage.getItem("profileInactiveDays") != null
        ? "Edit"
        : "Renew";
    let height1 = Number(this.state.feet) * 12 + Number(this.state.inch);
    let casteArray = this.state.desiredCaste.split(",");
    let cast2=this.state.desiredCaste1!=""?(","+this.state.desiredCaste1):""
    let multidesiredcaste = casteArray.filter((v) => v != "Other Specify").toString(",")+cast2
    let profileDetails = {
      profileId: this.state.profileId,
      profileFor: this.state.profileFor,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      gender: this.state.gender,
      dob: this.state.dob,
      height: height1,
      maritalStatus: this.state.maritalStatus,
      motherName: this.state.motherName,
      motherAge: this.state.motherAge,
      motherOccupation: this.state.motherOccupation,
      fatherName: this.state.fatherName,
      fatherAge: this.state.fatherAge,
      fatherOccupation: this.state.fatherOccupation,
      familyIncome: this.state.familyIncome,
      familyValues: this.state.familyValues,
      city: this.state.city,
      state: this.state.statee,
      highestEducation: this.state.highestEducation,
      ugDegree: this.state.ugDegree,
      ugCollege: this.state.ugCollege,
      pgDegree: this.state.pgDegree,
      pgCollege: this.state.pgCollege,
      occupation: this.state.occupation,
      income: this.state.income,
      desiredAgeGroup: this.state.desiredAgeGroup,
      desiredHeight: this.state.desiredHeight,
      
      desiredMaritalStatus: this.state.desiredMaritalStatus,
      caste:this.state.caste == "Other Specify" ? this.state.cast1: this.state.caste,
      gotra: this.state.gotra,
      rasi: this.state.rasi,
      nakshatra: this.state.nakshatra,
      desiredCaste:multidesiredcaste,
      desiredGotra: this.state.desiredGotra,
      desiredGender: this.state.desiredGender,
      userId: sessionStorage.getItem("userId"),
      PlaceOfBirth: this.state.PlaceOfBirth,
      desiredPlaceOfBirth: this.state.desiredPlaceOfBirth,
      actionrequired:
        sessionStorage.getItem("status") == "ACTIVE" &&
        sessionStorage.getItem("profileStatus") === "ACTIVE" &&
        actionRequired,
    };

    if (
      sessionStorage.getItem("status") == "ACTIVE" &&
      sessionStorage.getItem("profileStatus") === "ACTIVE"
    ) {
      if (
        localStorage.getItem("ProfileInactiveDays") != null &&
        localStorage.getItem("ProfileInactiveDays") <= 15
      ) {
        ProfileService.profileDump(profileDetails)
          .then((result) => {
            switch (this.state.submitSource) {
              case "gotoPay":
                return this.setState({ redirect: "/payment" });
              case "stayprofile":
                return (window.location.pathname = "/profileEdit");
              default:
                return this.state.submitSource;
            }
          })
          .catch((error) => {
            message.error("Something went wrong! Please try againnnn!");
            this.setState({ submitted: "" });
          });
      } else {
        ProfileService.profileDump(profileDetails)
          .then((result) => {
            switch (this.state.submitSource) {
              case "gotoPay":
                return this.setState({ redirect: "/payment" });
              case "stayprofile":
                return (window.location.pathname = "/profileEdit");
              default:
                return this.state.submitSource;
            }
          })
          .catch((error) => {
            message.error("Something went wrong! Please try againeee!");
            this.setState({ submitted: "" });
          });
      }
    } else {
      ProfileService.updateProfile(profileDetails)
        .then((result) => {
          this.setState({ submitted: false });
          switch (this.state.submitSource) {
            case "gotoPay":
              return this.setState({ redirect: "/payment" });
            case "stayprofile":
              return (window.location.pathname = "/profileEdit");
            case "stayprofile":
              return message.success("saved profiles");
            default:
              return this.state.submitSource;
          }
        })
        .catch((error) => {
          message.error("Something went wrong! Please try again!");
          this.setState({ submitted: "" });
        });
    }
  }
  validate1() {
    if (this.state.activationStatus === "INACTIVE") {
      this.setState({ submitSource: "stayprofile" });
    } else {
      this.setState({ submitSource: "saveProfile" });
    }
    if (
      sessionStorage.getItem("status") === "ACTIVE" &&
      sessionStorage.getItem("ProfileInactiveDays") <= 15
    ) {
      this.setState({ submitSource: "stayprofile" });
    }
    if (this.state.profileFor == "") {
      message.warn("Profile For cannot be empty.");
      return false;
    }

    if (this.state.statee == "") {
      message.warn("State cannot be empty.");
      return false;
    }
    if (this.state.gender == "") {
      message.warn("Gender cannot be empty.");

      return false;
    }
    if (this.state.maritalStatus == "") {
      message.warn("Marital Status cannot be empty.");

      return false;
    }
    if (this.state.familyValues == "") {
      message.warn("Family Values cannot be empty.");

      return false;
    }
    if (this.state.PlaceOfBirth == "") {
      message.warn("placeOfBirth Values cannot be empty.");

      return false;
    }
    if (this.state.desiredPlaceOfBirth == "") {
      message.warn("placeOfBirth Values cannot be empty.");

      return false;
    }
    if (this.state.desiredAgeGroup == "") {
      message.warn("desiredAgeGroup Values cannot be empty.");

      return false;
    }
    if (this.state.desiredHeight == "") {
      message.warn("desiredHeight Values cannot be empty.");

      return false;
    }
    else {
      this.saveChanges();
    }
  }
  validate() {
    if (sessionStorage.getItem("status") === "INACTIVE ") {
      this.setState({ toggole: true });
      this.setState({ submitSource: "saveProfile" });
    } else {
      this.setState({ toggole: true });
      this.setState({ submitSource: "gotoPay" });
    }

    if (this.state.profileFor == "") {
      message.warn("Profile For cannot be empty.");
      return false;
    }

    if (this.state.statee == "") {
      message.warn("State cannot be empty.");
      return false;
    }
    if (this.state.gender == "") {
      message.warn("Gender cannot be empty.");

      return false;
    }
    if (this.state.maritalStatus == "") {
      message.warn("Marital Status cannot be empty.");

      return false;
    }
    if (this.state.familyValues == "") {
      message.warn("Family Values cannot be empty.");

      return false;
    }
    if (this.state.PlaceOfBirth == "") {
      message.warn("placeOfBirth Values cannot be empty.");

      return false;
    }
    if (this.state.desiredPlaceOfBirth == "") {
      message.warn("placeOfBirth Values cannot be empty.");

      return false;
    }
    if (this.state.desiredAgeGroup == "") {
      message.warn("desiredAgeGroup Values cannot be empty.");

      return false;
    }
    if (this.state.desiredHeight == "") {
      message.warn("desiredHeight Values cannot be empty.");

      return false;
    }
    else {
      this.saveChanges();
    }
  }

  GetCountriesList = async () => {
    try {
      let res = await ListingService.getCountriesList();

      if (res.data) {
        this.setState({ country: res.data.message });
      }
    } catch (error) {
      return error;
    }
  };
  GetCasteList = async () => {
    try {
      let res = await ListingService.getCasteList();

      if (res.data) {
        this.setState({ cast: res.data.message });
      }
    } catch (error) {
      return error;
    }
  };
  GetGotralist = async () => {
    try {
      let res = await ListingService.getGotraList();

let gotra=[""]
      if (res.data) {
        gotra.push(res.data.message)
        this.setState({ gotraList: gotra });
      }
      
    } catch (error) {
      return error;
    }
  };
  Getagelist = async () => {
    try {
      let res = await ListingService.getAgeList();

let age=[""]
      if (res.data) {
        age.push(res.data.message)
        this.setState({ ageList: age });
      }
      
    } catch (error) {
      return error;
    }
  };
  componentDidMount() {
    this.GetCountriesList();
    this.GetCasteList();
    this.Getagelist()
this.GetGotralist()
    window.scrollTo(0, 0);
    
    if (localStorage.getItem("redirectedFromCheckout")) {
      sessionStorage.removeItem("token");
      localStorage.setItem("transactionExpired", true);
      window.location.pathname = "/";
    }

    sessionStorage.getItem("profileStatus") === "ACTIVE" && sessionStorage.getItem("status") === "ACTIVE" &&
    sessionStorage.getItem("profileInactiveDays") <= 15 &&
    sessionStorage.getItem("profileInactiveDays") != null
      ? ProfileService.getProfileDumpData(sessionStorage.getItem("userId") ).then((result) => {
          if (result.data.artifact) {
            this.setState({
              profileImage:
                constant.AVATAR_PATH + result.data.artifact.avatarFileName,
              horoscopeImage:
                constant.HOROSCOPE_PATH +
                result.data.artifact.horoscopeFileName,
              btnDisplay:
                result.data.artifact.horoscopeFileName === ""
                  ? "none"
                  : "block",
              photo1Image:
                constant.PHOTO1_PATH + result.data.artifact.photo1FileName,
              photo2Image:
                constant.PHOTO2_PATH + result.data.artifact.photo2FileName,
            });
          }
          if (result.data.profile) {
            this.setState({
              profileId: result.data.profile.profileId,
              profileNumber: result.data.profile.profileNumber,
              profileFor: result.data.profile.profileFor,
              firstName: result.data.profile.firstName,
              lastName: result.data.profile.lastName,
              gender: result.data.profile.gender,
              dob: result.data.profile.dob,
              height: result.data.profile.height,
              maritalStatus: result.data.profile.maritalStatus,
              motherName: result.data.profile.motherName,
              motherAge: result.data.profile.motherAge,
              motherOccupation: result.data.profile.motherOccupation,
              fatherName: result.data.profile.fatherName,
              fatherAge: result.data.profile.fatherAge,
              fatherOccupation: result.data.profile.fatherOccupation,
              familyIncome: result.data.profile.familyIncome,
              familyValues: result.data.profile.familyValues,
              city: result.data.profile.city,
              statee: result.data.profile.state,
              highestEducation: result.data.profile.highestEducation,
              ugDegree: result.data.profile.ugDegree,
              ugCollege: result.data.profile.ugCollege,
              pgDegree: result.data.profile.pgDegree,
              pgCollege: result.data.profile.pgCollege,
              occupation: result.data.profile.occupation,
              income: result.data.profile.income,
              desiredAgeGroup: result.data.profile.desiredAgeGroup,
              desiredHeight: result.data.profile.desiredHeight,
              
              desiredMaritalStatus: result.data.profile.desiredMaritalStatus,
              activationStatus: result.data.profile.status,
              caste: result.data.profile.caste,
              gotra: result.data.profile.gotra,
              rasi: result.data.profile.rasi,
              nakshatra: result.data.profile.nakshatra,
              desiredCaste: result.data.profile.desiredCaste,
              desiredGotra: result.data.profile.desiredGotra,
              desiredGender: result.data.profile.desiredGender,
              PlaceOfBirth: result.data.profile.PlaceOfBirth,
              desiredPlaceOfBirth: result.data.profile.desiredPlaceOfBirth,
              feet: result.data.profile.height_feet,
              inch: result.data.profile.height_inches,
            });
          }
        })
      : ProfileService.getById(sessionStorage.getItem("userId")).then(
          (result) => {
            if (result.data.artifact) {
              this.setState({
                profileImage:
                  constant.AVATAR_PATH + result.data.artifact.avatarFileName,
                horoscopeImage:
                  constant.HOROSCOPE_PATH +
                  result.data.artifact.horoscopeFileName,
                btnDisplay:
                  result.data.artifact.horoscopeFileName === ""
                    ? "none"
                    : "block",
                photo1Image:
                  constant.PHOTO1_PATH + result.data.artifact.photo1FileName,
                photo2Image:
                  constant.PHOTO2_PATH + result.data.artifact.photo2FileName,
                profileNumber: result.data.artifact.userId,
              });
            }
            if (result.data.profile) {
              this.setState({
                profileId: result.data.profile.profileId,
                profileNumber: result.data.profile.profileNumber,
                profileFor: result.data.profile.profileFor,
                firstName: result.data.profile.firstName,
                lastName: result.data.profile.lastName,
                gender: result.data.profile.gender,
                dob: result.data.profile.dob,
                height: result.data.profile.height,
                maritalStatus: result.data.profile.maritalStatus,
                motherName: result.data.profile.motherName,
                motherAge: result.data.profile.motherAge,
                motherOccupation: result.data.profile.motherOccupation,
                fatherName: result.data.profile.fatherName,
                fatherAge: result.data.profile.fatherAge,
                fatherOccupation: result.data.profile.fatherOccupation,
                familyIncome: result.data.profile.familyIncome,
                familyValues: result.data.profile.familyValues,
                city: result.data.profile.city,
                statee: result.data.profile.state,
                highestEducation: result.data.profile.highestEducation,
                ugDegree: result.data.profile.ugDegree,
                ugCollege: result.data.profile.ugCollege,
                pgDegree: result.data.profile.pgDegree,
                pgCollege: result.data.profile.pgCollege,
                occupation: result.data.profile.occupation,
                income: result.data.profile.income,
                desiredAgeGroup: result.data.profile.desiredAgeGroup,
                desiredHeight: result.data.profile.desiredHeight,
               
                desiredMaritalStatus: result.data.profile.desiredMaritalStatus,
                activationStatus: result.data.profile.status,
                caste: result.data.profile.caste,
                gotra: result.data.profile.gotra,
                rasi: result.data.profile.rasi,
                nakshatra: result.data.profile.nakshatra,
                desiredCaste: result.data.profile.desiredCaste,
                desiredGotra: result.data.profile.desiredGotra,
                desiredGender: result.data.profile.desiredGender,
                PlaceOfBirth: result.data.profile.PlaceOfBirth,
                desiredPlaceOfBirth: result.data.profile.desiredPlaceOfBirth,
                feet: result.data.profile.height_feet,
                inch: result.data.profile.height_inches,
              });
            }
          }
        );
  }

  render() {
    const menu = (
      <Menu>
        {this.state.btnDisplay !== "none" ? (
          <Menu.Item>
            <Button
              type="text"
              onClick={(e) =>
                (window.location.href = this.state.horoscopeImage)
              }
            >
              View
            </Button>
          </Menu.Item>
        ) : (
          <></>
        )}{" "}
        <Menu.Item>
          <UploadPic
            handleImageChange={this.handleHoroscopeImageChange}
            horoscope={this.state.horoscopeImage}
            type="horoscope"
          />
        </Menu.Item>
      </Menu>
    );
    let { Option } = Select;
    let { Step } = Steps;
    const status = ["Single", "Widowed", "Divorced"];
    let children = [];
    for (let i = 0; i < status.length; i++) {
      children.push(<Option key={status[i]}>{status[i]}</Option>);
    }

    let progressBar, paymentBtn;
    if (this.state.activationStatus === "INACTIVE") {
      paymentBtn = "submit";
      progressBar = (
        <>
          <Steps size="small" current={0}>
            <Step title="Update Profile" />
            <Step title="Payment Information" />
            <Step title="Complete & Activate" />
          </Steps>
          <br />
        </>
      );
    } else {
      paymentBtn = "hidden";
      progressBar = <></>;
    }

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { city: this.state.city, statee: this.state.statee },
          }}
        />
      );
    }

    return (
      <>
        <div>
          <TokenExpired />
        </div>
        <InnerBanner />
       
        <div style={{ marginTop: "-10%" }}>
          {sessionStorage.getItem("status") === "ACTIVE" ? (
            <Breadcrumbs title={["Profile", "Edit"]} href={["/profile"]} />
          ) : (
            <Breadcrumbs title={["Edit"]} href={["/"]} />
          )}
        </div>
        <div className="w3ls-list" style={{ backgroundColor: "#E5D2BF" }}>
          <div className="container">
            {progressBar}
            <h2 style={{ color: "#401A0A" }}>Profile</h2>
            <div
              className="col-md-9 profiles-list-agileits"
              style={{ marginLeft: "12.5%" }}
            >
              <div className="single_w3_profile">
                <div className="agileits_profile_image">
                  <div>
                    <Avatar
                      size={170}
                      icon={<UserOutlined />}
                      src={this.state.profileImage}
                    />
                    <UploadPic
                      handleImageChange={this.handleProfileImageChange}
                      type="avatar"
                    />
                  </div>
                </div>
                <div className="w3layouts_details">
                  {sessionStorage.getItem("status") &&
                  sessionStorage.getItem("status") === "ACTIVE" &&
                  sessionStorage.getItem("profileStatus") &&
                  sessionStorage.getItem("profileStatus") === "ACTIVE" ? (
                    <h4 style={{ color: "#00A800" }}>
                      Profile ID : <b>{this.state.profileNumber}</b> (Active)
                      
                    </h4>
                  ) : (
                    <h4 style={{ color: "#ff4c4c" }}>
                      Profile ID : <b>{this.state.profileNumber}</b> (Not
                      Activated)
                    </h4>
                  )}
                  <div className="row">
                    <div className="col-sm-5">
                      <p>
                        {this.state.firstName + " " + this.state.lastName}
                        <br />
                        {/* {moment(new Date(this.state.dob),'DD-MMMM-YYYY').clone()} */}
                       
                        {/* {moment(this.state.dob).format('yyyy-mmmm-dd')} */}
                       {this.state.dob!=""&&( <Moment format="DD-MM-YYYY">{this.state.dob}</Moment>)}
                        <br />
                        {this.state.occupation}
                        <br />
                        {this.state.income}
                      </p>
                    </div>
                    <div
                      className="col-sm-6 pull-left"
                      style={{ textAlign: "center" }}
                    >
                      <br />

                      <Space>
                        {" "}
                        <Avatar
                          shape="square"
                          size={100}
                          icon={<UserOutlined />}
                          src={this.state.photo1Image}
                        />
                        <Avatar
                          className=""
                          shape="square"
                          size={100}
                          icon={<UserOutlined />}
                          src={this.state.photo2Image}
                        />
                      </Space>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-sm-5">
                      <Dropdown overlay={menu}>
                        <Button type="warning">
                          Horoscope
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </div>
                    <div className="col-sm-6" style={{ textAlign: "center" }}>
                      <UploadPic
                        handleImageChange={this.handlePhotosChange}
                        photos={[
                          this.state.photo1Image,
                          this.state.photo2Image,
                        ]}
                        type="photos"
                      />
                    </div>
                  </div>
                </div>

                <div className="clearfix"></div>
              </div>
              <div className="profile_w3layouts_details">
                <form
                  id="profile"
                  ref={this.form}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="agileits_aboutme">
                    <div className="col-md-12">
                      <div className="col-md-2 required control-label1">
                        Profile for:
                      </div>
                      <div className="col-md-6 ">
                        <Select
                          status="warning"
                          style={{ width: "100%" }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(e) => this.setState({ profileFor: e })}
                          className=" "
                          value={this.state.profileFor}
                          id="profileFor"
                        >
                          <Option value="Myself">Me</Option>
                          <Option value="Son">My Son</Option>
                          <Option value="Daughter">My Daughter</Option>
                          <Option value="Brother">My Brother</Option>
                          <Option value="Sister">My Sister</Option>
                          <Option value="Relative">My Relative</Option>
                          <Option value="Friend">My Friend</Option>
                        </Select>
                      </div>
                    </div>
                    <br />
                    <h5>Brief Details:</h5>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        {" "}
                        Full Name:{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ firstName: e.target.value })
                            }
                            required
                            type="text"
                            defaultValue={this.state.firstName}
                            id="firstName"
                            className="form-control"
                            placeholder="First Name"
                          ></input>
                        </div>
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ lastName: e.target.value })
                            }
                            required
                            type="text"
                            defaultValue={this.state.lastName}
                            id="lastName"
                            className="form-control"
                            placeholder="Last Name"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        {" "}
                        Gender:{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.setState({ gender: e })}
                            required
                            // className="form-control"
                            value={this.state.gender}
                            id="gender"
                          >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
   
                      
                        Date of Birth:{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                        <DatePicker className="col-md-12" value={this.state.dob!=""&&(moment.utc((new Date(this.state.dob))).clone())} 
                        // onChange={(date, dateString)=>{this.handleDateChange(dateString)}}
                        onChange={(date, dateString)=>this.setState({dob:dateString})}
                        />
                          
                        </div>
                    
                        <div className="col-md-4">
                         
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        Height (Feet/Inch):{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-4">
                          
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Select Feet"
                            value={this.state.feet}
                            onChange={(e) => this.setState({ feet: e })}
                          >
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                            <Option value="6">6</Option>
                            <Option value="7">7</Option>
                            <Option value="8">8</Option>
                          </Select>
                        </div>
                        <div className="col-md-4">
                          {/* <input
                            onBlur={(e) =>this.setState({height:this.state.height})}
                            required
                            type="text"
                            defaultValue={this.state.height}
                            id="height"
                            className="form-control"
                            placeholder="total inches"
                          ></input> */}
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Select Inche"
                            value={this.state.inch}
                            onChange={(e) => this.setState({ inch: e })}
                          >
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="5">5</Option>
                            <Option value="6">6</Option>
                            <Option value="7">7</Option>
                            <Option value="8">8</Option>
                            <Option value="9">9</Option>
                            <Option value="10">10</Option>
                            <Option value="11">11</Option>
                            <Option value="12">12</Option>
                          </Select>
                        </div>
                        <div className="col-md-4">
                          {/* <input
                            onChange={() =>
                              this.setState({ height: Number( this.state.feet)*12 + Number(this.state.inch) })
                            }
                            placeholder="Hight in (inches)"
                            required
                            type="text"
                            value={(this.state.feet)||(this.state.inche)?
                              Number( this.state.feet)*12 + Number(this.state.inch):""
                               
                            }
                            id="height"
                            className="form-control"
                          
                          ></input> */}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        {" "}
                        Marital Status:{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) =>
                              this.setState({ maritalStatus: e })
                            }
                            required
                            className="rounded"
                            value={this.state.maritalStatus}
                            id="maritalStatus"
                          >
                            <Option value="Single">Single</Option>
                            <Option value="Widowed">Widowed</Option>
                            <Option value="Divorced">Divorced</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        Place Of Birth :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-11">
                          {}
                          <Select
                            mode="single"
                            style={{ width: "100%" }}
                            placeholder="Please Select"
                            value={this.state.PlaceOfBirth}
                            id="placeOfBirthValues"
                            required="true"
                            onChange={(e) =>
                              this.setState({
                                PlaceOfBirth: e,
                              })
                            }
                          >
                            {this.state.country &&
                              this.state.country.length &&
                              this.state.country?.map((e, i) => {
                                return (
                                  <>
                                    <Option value={e.country_name}>
                                      {e.country_name}
                                    </Option>
                                  </>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <h5>Family Details:</h5>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        Mother :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ motherName: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            style={
                              this.state.motherName === ""
                                ? { border: "1px solid red" }
                                : { border: "" }
                            }
                            placeholder="Name"
                            id="motherName"
                            defaultValue={this.state.motherName}
                            required
                          ></input>
                        </div>
                        <div className="col-md-2">
                          <input
                            onBlur={(e) =>
                              this.setState({ motherAge: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Age"
                            defaultValue={this.state.motherAge}
                            id="motherAge"
                          ></input>
                        </div>
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({
                                motherOccupation: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Occupation"
                            defaultValue={this.state.motherOccupation}
                            id="motherOccupation"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        Father :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ fatherName: e.target.value })
                            }
                            onChange={""}
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            defaultValue={this.state.fatherName}
                            id="fatherName"
                            style={
                              this.state.fatherName === ""
                                ? { border: "1px solid red" }
                                : { border: "" }
                            }
                            required
                          ></input>
                        </div>

                        <div className="col-md-2">
                          <input
                            onBlur={(e) =>
                              this.setState({ fatherAge: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Age"
                            defaultValue={this.state.fatherAge}
                            id="fatherAge"
                          ></input>
                        </div>
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({
                                fatherOccupation: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Occupation"
                            defaultValue={this.state.fatherOccupation}
                            id="fatherOccupation"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Family Income (in USD):{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ familyIncome: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Income"
                            defaultValue={this.state.familyIncome}
                            id="familyIncome"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        City/State :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ city: e.target.value })
                            }
                            required
                            type="text"
                            className="form-control"
                            placeholder="City"
                            defaultValue={this.state.city}
                            id="city"
                          ></input>
                        </div>
                        <div className="col-md-5">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.setState({ statee: e })}
                            required
                            // className="form-control"
                            value={this.state.statee}
                            id="state"
                          >
                            <Option value="AL">Alabama</Option>
                            <Option value="AK">Alaska</Option>
                            <Option value="AZ">Arizona</Option>
                            <Option value="AR">Arkansas</Option>
                            <Option value="CA">California</Option>
                            <Option value="CO">Colorado</Option>
                            <Option value="CT">Connecticut</Option>
                            <Option value="DE">Delaware</Option>
                            <Option value="DC">District Of Columbia</Option>
                            <Option value="FL">Florida</Option>
                            <Option value="GA">Georgia</Option>
                            <Option value="HI">Hawaii</Option>
                            <Option value="ID">Idaho</Option>
                            <Option value="IL">Illinois</Option>
                            <Option value="IN">Indiana</Option>
                            <Option value="IA">Iowa</Option>
                            <Option value="KS">Kansas</Option>
                            <Option value="KY">Kentucky</Option>
                            <Option value="LA">Louisiana</Option>
                            <Option value="ME">Maine</Option>
                            <Option value="MD">Maryland</Option>
                            <Option value="MA">Massachusetts</Option>
                            <Option value="MI">Michigan</Option>
                            <Option value="MN">Minnesota</Option>
                            <Option value="MS">Mississippi</Option>
                            <Option value="MO">Missouri</Option>
                            <Option value="MT">Montana</Option>
                            <Option value="NE">Nebraska</Option>
                            <Option value="NV">Nevada</Option>
                            <Option value="NH">New Hampshire</Option>
                            <Option value="NJ">New Jersey</Option>
                            <Option value="NM">New Mexico</Option>
                            <Option value="NY">New York</Option>
                            <Option value="NC">North Carolina</Option>
                            <Option value="ND">North Dakota</Option>
                            <Option value="OH">Ohio</Option>
                            <Option value="OK">Oklahoma</Option>
                            <Option value="OR">Oregon</Option>
                            <Option value="PA">Pennsylvania</Option>
                            <Option value="RI">Rhode Island</Option>
                            <Option value="SC">South Carolina</Option>
                            <Option value="SD">South Dakota</Option>
                            <Option value="TN">Tennessee</Option>
                            <Option value="TX">Texas</Option>
                            <Option value="UT">Utah</Option>
                            <Option value="VT">Vermont</Option>
                            <Option value="VA">Virginia</Option>
                            <Option value="WA">Washington</Option>
                            <Option value="WV">West Virginia</Option>
                            <Option value="WI">Wisconsin</Option>
                            <Option value="WY">Wyoming</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        Family Values :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.setState({ familyValues: e })}
                            required
                            className=""
                            value={this.state.familyValues}
                            id="familyValues"
                          >
                            <Option value="Orthodox">Orthodox</Option>
                            <Option value="Conservative">Conservative</Option>
                            <Option value="Moderate">Moderate</Option>
                            <Option value="Liberal">Liberal</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>

                    <h5>Education Details:</h5>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Highest Level of Education :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({
                                highestEducation: e.target.value,
                              })
                            }
                            type="text"
                            className="form-control"
                            placeholder=" Highest Level of Education"
                            defaultValue={this.state.highestEducation}
                            id="familyIncome"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Bachelor's Degree :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ ugDegree: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Bachelor's Degree"
                            defaultValue={this.state.ugDegree}
                            id="familyIncome"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        School/College :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ ugCollege: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder=" School/College"
                            defaultValue={this.state.ugCollege}
                            id="familyIncome"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Graduate Degree :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ pgDegree: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder=" Graduate Degree"
                            defaultValue={this.state.pgDegree}
                            id="familyIncome"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Graduate School :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ pgCollege: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="Graduate School"
                            defaultValue={this.state.pgCollege}
                            id="familyIncome"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <h5>Employment Details:</h5>

                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Occupation:{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ occupation: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder="occupation"
                            defaultValue={this.state.occupation}
                            id="familyIncome"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Annual Income (in USD):{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <input
                            onBlur={(e) =>
                              this.setState({ income: e.target.value })
                            }
                            type="text"
                            className="form-control"
                            placeholder=" Annual Income (in USD)"
                            defaultValue={this.state.income}
                            id="familyIncome"
                          ></input>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>

                    <h5>Optional Details:</h5>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Caste :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          {this.state.caste &&
                          this.state.caste == "Other Specify" ? (
                            <>
                              <Row>
                                <Select
                                  style={{ width: "100%" }}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(e) => this.handleChangeslect(e)}
                                  value={this.state.caste}
                                  id="caste"
                                >
                                  {this.state.cast &&
                                    this.state.cast.length != 0 &&
                                    this.state.cast?.map((e, i) => {
                                      return (
                                        <>
                                          <Option value={ e.castename &&e.castename == "Other Specify" ? "": e.castename}
                                          >
                                            {e.castename && e.castename == "Other Specify" ? "": e.castename}
                                          </Option>
                                        </>
                                      );
                                    })}
                                </Select>
                                <div style={{ marginTop: "4%" }}>
                                  <input
                                    onChange={(e) => {
                                      this.setState({ cast1: e.target.value });
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your caste"
                                    id="familyIncome"
                                  />
                                </div>
                              </Row>
                            </>
                          ) : (
                            <Select
                              style={{ width: "100%" }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(e) => this.handleChangeslect(e)}
                              value={this.state.caste}
                              id="caste"
                            >
                              {this.state.cast &&
                                this.state.cast.length != 0 &&
                                this.state.cast?.map((e, i) => {
                                  return (
                                    <>
                                      <Option value={e.castename}>
                                        {e.castename}
                                      </Option>
                                    </>
                                  );
                                })}
                            </Select>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Gotra :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.setState({ gotra: e })}
                            // className="form-control"
                            value={this.state.gotra}
                            id="gotra"
                          >
                              {this.state.gotraList[1]&&this.state.gotraList.length!=0&&this.state.gotraList[1]?.map((e,i)=>{
return(<Option value={e.ghotraname}>{e.ghotraname}</Option>)
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">Rasi : </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.setState({ rasi: e })}
                            // className="form-control"
                            value={this.state.rasi}
                            id="rasi"
                          >
                            <Option value="Mesha">Mesha</Option>
                            <Option value="Rishabha">Rishabha</Option>
                            <Option value="Mithuna">Mithuna</Option>
                            <Option value="Kataka">Kataka</Option>
                            <Option value="Simha">Simha</Option>
                            <Option value="Kanya">Kanya</Option>
                            <Option value="Thula">Thula</Option>
                            <Option value="Vrischika">Vrischika</Option>
                            <Option value="Dhanus">Dhanus</Option>
                            <Option value="Makara">Makara</Option>
                            <Option value="Kumbha">Kumbha</Option>
                            <Option value="Meena">Meena</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Star/Nakshatra :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <Select
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => this.setState({ nakshatra: e })}
                            // className="form-control"
                            value={this.state.nakshatra}
                            id="nakshatra"
                          >
                            <Option value="Ashwini">Ashwini</Option>
                            <Option value="Bharani">Bharani</Option>
                            <Option value="Krittika">Krittika</Option>
                            <Option value="Rohini">Rohini</Option>
                            <Option value="Mrigashirsha">Mrigashirsha</Option>
                            <Option value="Ardra">Ardra</Option>
                            <Option value="Punarvasu">Punarvasu</Option>
                            <Option value="Pushyami">Pushyami</Option>
                            <Option value="Aslesha">Aslesha</Option>
                            <Option value="Makha">Makha</Option>
                            <Option value="Purvaphalguni">Purvaphalguni</Option>
                            <Option value="Uttaraphalguni">
                              Uttaraphalguni
                            </Option>
                            <Option value="Hasta">Hasta</Option>
                            <Option value="Chitra">Chitra</Option>
                            <Option value="Swati">Swati</Option>
                            <Option value="Visakha">Visakha</Option>
                            <Option value="Anuradha">Anuradha</Option>
                            <Option value="Jyeshta">Jyeshta</Option>
                            <Option value="Moola">Moola</Option>
                            <Option value="Purvashada">Purvashada</Option>
                            <Option value="Uttarashada">Uttarashada</Option>
                            <Option value="Shravana">Shravana</Option>
                            <Option value="Danishta">Danishta</Option>
                            <Option value="Satabhisha">Satabhisha</Option>
                            <Option value="Purvabhadra">Purvabhadra</Option>
                            <Option value="Uttarabhadra">Uttarabhadra</Option>
                            <Option value="Revati">Revati</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <h5>Desired Partner Preferences:</h5>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        Age Group:{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-5">
                          <Select
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              this.setState({ desiredAgeGroup: e })
                            }
                            required
                            // className="form-control"
                            value={this.state.desiredAgeGroup}
                            id="desiredAgeGroup"
                          >
                            <Option value="18-20">18-20</Option>
                            <Option value="21-23">21-23</Option>
                            <Option value="24-27">24-27</Option>
                            <Option value="28-30">28-30</Option>
                            <Option value="30-35">30-35</Option>
                            <Option value="35-40">35-40</Option>
                            <Option value="40-45">40-45</Option>
                            <Option value="45-50">45-50</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        Height (in inches):{" "}
                      </label>
                      <div className="col-md-8">
                        {" "}
                        <div className="col-md-5">
                          <Select
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              this.setState({ desiredHeight: e })
                            }
                            required
                            // className="form-control"
                            value={this.state.desiredHeight}
                            id="desiredHeight"
                          >
                           <Option value={"60-70"}>60-70</Option>
                           <Option value={"70-80"}>70-80</Option>
                           <Option value={"50-60"}>50-60</Option>
                           <Option value={"40-50"}>40-50</Option>
                           <Option value={"30-40"}>30-40</Option>
                           <Option value={"80-90"}>80-90</Option>
                           <Option value={"90-100"}>90-100</Option>
                            
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Marital Status :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-11">
                          {/* <Select
                            mode="multiple"
                           
                            style={{ width: "100%" }}
                            placeholder="Please Select"
                            
                            value={this.state.desiredMaritalStatus
                              ?.toString()
                              .split(",")}
                            onChange={(e) =>
                              this.setState({
                                desiredMaritalStatus:
                                  e.length === 0
                                    ? ""
                                    : Object.values(e).join(","),
                              })
                            }
                          >
                            <Option key="Single">Single</Option>
                            <Option key="Widowed">Widowed</Option>
                            <Option key="Divorced">Divorced</Option>
                          </Select> */}
                          <Select
                            mode={
                              this.state.desiredMaritalStatus != "" &&
                              "multiple"
                            }
                            value={
                              this.state.desiredMaritalStatus != "" &&
                              this.state.desiredMaritalStatus?.split(",")
                            }
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            tokenSeparators={[","]}
                          >
                            {children}
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Caste :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-11">
                          {this.state.desiredCaste &&
                          this.state.desiredCaste.includes("Other Specify") ? (
                            <>
                             <Select
                                mode={ this.state.desiredCaste != "" && "multiple"}
                                style={{ width: "100%" }}
                                placeholder="Please Select"
                                value={this.state.desiredCaste.split(",")}
                                onChange={(e) => {this.handleDesiredCaste(e);
                                }}
                               
                              >
                                {this.state.cast &&
                                  this.state.cast.length != 0 &&
                                  this.state.cast?.map((e) => {
                                    return (
                                      <>
                                        <Option value={e.castename}>
                                          {e.castename}
                                        </Option>
                                      </>
                                    );
                                  })}
                              </Select>
                            <div style={{ paddingTop: "3%" }}>
                                <input
                                  onChange={(e) => {this.setState({
                                      desiredCaste1: e.target.value,
                                    });
                                  }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your desired cast"
                                  // Value={this.state.desiredCaste1}
                                  id="familyIncome"
                                />
                              </div>
                            </>
                          ) : (
                            <Select
                              mode={this.state.desiredCaste != "" && "multiple"}
                             
                              style={{ width: "100%" }}
                              placeholder="Please Select"
                              value={this.state.desiredCaste !="" && this.state.desiredCaste.split(",")}
                              onChange={(e) => {this.handleDesiredCaste(e)}}
                              default
                              // onChange={(e) =>this.setState({desiredGotra: e.toString(","), }) }
                              // onChange={(e) => this.handleChangeslect(e)}
                              // value={this.state.caste}
                            //   onChange={(e)=>this.setState({ desiredCaste: e.toString(","),
                            // })}
                            >
                              {this.state.cast &&
                                this.state.cast.length != 0 &&
                                this.state.cast?.map((e, i) => {
                                  return (
                                  
                                      <Option value={e.castename}>
                                        {e.castename}
                                      </Option>
                                  
                                  );
                                })}
                            </Select>
                          )}
                        </div>  
                       
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Gotra :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-11">
                          <Select
                            mode={this.state.desiredGotra != "" && "multiple"}
                            style={{ width: "100%" }}
                            placeholder="Please Select"
                            value={this.state.desiredGotra != "" &&this.state.desiredGotra.split(",")}
                            required
                            default
                            onChange={(e) =>this.setState({desiredGotra: e.toString(","), }) }
                          >
                             {this.state.gotraList[1]&&this.state.gotraList.length!=0&&this.state.gotraList[1]?.map((e,i)=>{
return(<Option value={e.ghotraname}>{e.ghotraname}</Option>)
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1">
                        Gender :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-11">
                          <Select
                            mode="single"
                            style={{ width: "100%" }}
                            placeholder="Please Select"
                            value={this.state.desiredGender}
                            id="Profilegender"
                            required
                            onChange={(e) =>
                              this.setState({
                                desiredGender: e.toString(","),
                              })
                            }
                          >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label1 required">
                        Place Of Birth :{" "}
                      </label>
                      <div className="col-md-8">
                        <div className="col-md-11">
                          <Select
                            mode={
                              this.state?.desiredPlaceOfBirth != "" &&
                              "multiple"
                            }
                            style={{ width: "100%" }}
                            placeholder="Please Select"
                            value={
                              this.state?.desiredPlaceOfBirth != "" &&
                              this.state?.desiredPlaceOfBirth?.split(",")
                            }
                            required
                            default
                            onChange={(e) =>
                              this.setState({
                                desiredPlaceOfBirth: e.toString(","),
                              })
                            }
                          >
                            {this.state.country &&
                              this.state.country.length != 0 &&
                              this.state.country.map((e, i) => {
                                return (
                                  <>
                                    <Option value={e.country_name}>
                                      {e.country_name}
                                    </Option>
                                  </>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>

                    <div
                      className="row"
                      style={{
                       
                       padding:"2%"
                      }}
                    >
                      {sessionStorage.getItem("profileStatus") == "ACTIVE" &&
                        sessionStorage.getItem("status") == "ACTIVE" &&
                        localStorage.getItem("profileInactiveDays") <= 15 && (
                          <AlertBox
                            callback={this.validate1}
                            ok="Pay Now"
                            cancel="Later "
                            button="Save Changess"
                            title="Save Changes"
                            content="Changes have been saved Temporarily . On making payment for renewal the new changes will be reflected in your profile. Do you want to pay now ?
                  "
                          />
                        )}
                      {sessionStorage.getItem("profileStatus") == "ACTIVE" &&
                      sessionStorage.getItem("status") == "ACTIVE" &&
                      localStorage.getItem("profileInactiveDays") > 15 ? (
                        <AlertBox
                          callback={this.validate1}
                          ok="Pay Now"
                          cancel="Later "
                          button="Save Changess"
                          title="Save Changes"
                          content="Changes sent for approval. You will receive an email once approved.
                    "
                        />
                      ) : (
                        <input
                          required
                          onClick={this.validate1}
                          type="submit"
                          disabled={this.state.submitted}
                          className="btn btn-primary"
                          id="saveProfile"
                          value="Save Changes"
                        />
                      )}
                      <div  className="pull-right"style={{ padding:"2%"}}>
                        <AlertBox
                          desabled={
                            sessionStorage.getItem("profileStatus") ==
                              "ACTIVE" &&
                            sessionStorage.getItem("status") == "ACTIVE"
                              ? true
                              : false
                          }
                          callback={this.validate}
                          button="Proceed To Payment"
                          title="Proceed To Payment"
                          content=" Please verify your profile details before making payment. Once paid, your profile will be become active.
                  Once active, your profile will locked and you will not be able to make any changes."
                        />
                      </div>
                      {/* <input
                        required
                        onClick={this.validate}
                        type={paymentBtn}

                        //  disabled={this.state.submitted}
                        
                        // id="gotoPay"
                        value="Proceed To Payment"
                      /> */}
                      <div className="clearfix"> </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-9 container" style={{ marginLeft: "12.5%" }}>
              <p
                className=" single_w3_profile"
                style={{ color: "red", fontSize: "20px", padding: "5%" }}
              >
                Please verify your profile details before making payment. Once
                paid, your profile will become active. Once active, your profile
                will be locked and you will not be able to make any changes.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProfileEdit;
