import React, { Component } from "react";
import LoginService from "../../../src/services/LoginService";
import Fade from "react-reveal/Fade";
import ForgotPassword from "../forgotPassword";
import Recaptcha from "react-recaptcha";
import * as constant from "../../constants";
import Success from "../../success";
import { ToastContainer, toast } from "react-toastify";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link } from "react-router-dom";
import TokenExpired from "./tokenExpired";
import { Redirect,useLocation } from "react-router-dom";
import Notify from "../../services/Notify";
let captchaRef = React.createRef();

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.state = {
      password: "",
      confirmPassword: "",
      encodedId: this.props.match.params.encodedId,

      message: "",
      message1: "",
      submitted: "",
      fadeIn: false,
      captchaApproved: false,
      show: true,
    };
    
    
  //  sessionStorage.setItem("prevpath",window.location.pathname)
   
   
    this.changePasswordHandler = this.changePasswordHandler.bind(this);
    this.changeConfirmPassword = this.changeConfirmPassword.bind(this);
    this.validate = this.validate.bind(this);
    this.captchaCallback = this.captchaCallback.bind(this);
    this.verifyCaptchaCallback = this.verifyCaptchaCallback.bind(this);
  }

  changePasswordHandler = (event) => {
    if (
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        event.target.value
      )
    ) {
      event.target.setCustomValidity("");
    } else {
      event.target.setCustomValidity(
        "Please enter at least 8 characters including upper, lower & special case characters"
      );
    }
    this.setState({ password: event.target.value });
  };

  changeConfirmPassword = (e) => {
    this.setState({ confirmPassword: e.target.value });
  };

  validate=async()=> {
    if (this.form.current.reportValidity()) {
      if (!this.state.captchaApproved) {
        Notify.alert("Invalid Captcha!","center")
      
        return false;
      }
      if (this.state.password !== this.state.confirmPassword) {
        Notify.alert("Invalid Password!","center")
        
        return false;
      }
      if (this.state.password === this.state.confirmPassword) {
        this.setState({ submitted: true });
        let loginData = {
          encodedId: this.state.encodedId,
          password: this.state.password,
        };
     let res=await   LoginService.resetPassword(loginData)
     if(res&&res.data){
      window.location.pathname = "/";
     }
     else{
      this.setState({
        submitted: "",
        message1: "",
      });
     }
         
        

        return false;
      }
    }
  }

  onCaptchaChange() {}

  captchaCallback() {}

  verifyCaptchaCallback(response) {
    this.setState({ captchaApproved: true });
  }
   
  render() {
   
     localStorage.removeItem("true");

    var el = document.getElementById("myModal");
    if (el) {
      el.addEventListener("shown.bs.modal", function () {
        this.callback();
      });
      el.addEventListener("hidden.bs.modal", function () {
        this.onRecaptchaChange();
      });
    }
    return (
      <>
        <div>
        
         

          <div
            className="modal-dialog"
            onClick={() => this.setState({ fadeIn: false })}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Reset Password</h4>
              </div>
              <div className="modal-body">
                <h4>{this.state.message1}</h4>
                <div className="login-w3ls">
                  <form ref={this.form} onSubmit={(e) => e.preventDefault()}>
                    <div className="">
                      <label>Password:</label>
                      <div className="" style={{ display: "flex" }}>
                        <input
                          className="form-control"
                          required
                          id="password"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          type={this.state.show ? "password" : "text"}
                          onChange={this.changePasswordHandler}
                          autoCapitalize="off"
                          placeholder="Password"
                        />
                        <span
                          style={{ marginLeft: "1%" }}
                          className={
                            this.state.show
                              ? "btn btn-primary"
                              : "btn btn-success"
                          }
                          onClick={() =>
                            this.setState({ show: !this.state.show })
                          }
                        >
                          {this.state.show ? "Show" : "Hide"}{" "}
                        </span>
                      </div>
                      <div className="w3_modal_body_grid">
                        <PasswordStrengthBar
                          minLength={1}
                          password={this.state.password}
                        />
                      </div>
                    </div>
                    <div className="" style={{ marginTop: "-3%" }}>
                      <label>Confirm Password:</label>
                      <input
                        className="form-control"
                        required
                        id="password"
                        onChange={this.changeConfirmPassword}
                        autoCapitalize="off"
                        placeholder="Confirm Password"
                        type="password"
                      />
                    </div>

                    <div className="form-group signupCaptcha">
                      <br />
                      <div
                        className="col-md-12 text-center"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Recaptcha
                          className="signupCaptcha"
                          ref={(e) => (captchaRef = e)}
                          sitekey={constant.CAPTCHA_SITE_KEY}
                          render="explicit"
                          onChange={this.onCaptchaChange}
                          verifyCallback={this.verifyCaptchaCallback}
                          onloadCallback={this.captchaCallback}
                        />
                      </div>
                      <div className="clearfix"> </div>
                    </div>

                    <div className="text-center" style={{ marginTop: "-1.5%" }}>
                      <input
                        onClick={this.validate}
                        type="submit"
                        // disabled={this.state.submitted}
                        className="btn btn-primary"
                        value="Change Password"
                      />
                    </div>
                    <div className="clearfix"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ResetPassword;
