import axios from "axios";
import * as constant from "../constants";
class LoginService {
  getUsers(userId) {
    return axios.get(constant.LOGIN_API_URL);
  }
  activeUser(encodedId) {
    console.log()
    return axios.post(  constant.USER_API_URL + "/activateUser",encodedId,constant.config);
  }
  
  login(credentials) {
    return axios.post(constant.LOGIN_API_URL + "/signin", credentials, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  forgotPassword(email) {
    return axios.post(
      constant.LOGIN_API_URL + "/forgotPassword",
      { email: email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  changePassword(cpassword) {
    return axios.post(
      constant.LOGIN_API_URL + "/changePassword",
      cpassword,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  resetPassword(data) {
    return axios.post(
      constant.LOGIN_API_URL + "/resetPassword",
      data,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
}

export default new LoginService();
