import React from 'react'
import {  toast } from "react-toastify";
class Notify  {
    alert=(msg,)=>{
        const style = {fontSize: "20px", padding: "2%",backgroundColor: "darkorange",color: "white",};

        return  toast(msg,{ position: "top-center", style, hideProgressBar: true, });
       
    }

}
export default new Notify();
