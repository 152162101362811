import axios from "axios";
import * as constant from "../constants";
class UserService {
  getUsers() {
    return axios.get(constant.USER_API_URL);
  }
//constant.USER_API_URL +
  saveUser(user) {
    return axios.post( constant.USER_API_URL +"/create", 
    user, constant.config);
  }
  
}

export default new UserService();
