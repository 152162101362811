import React, { Component } from "react";
import UserService from "../services/UserService";
import PasswordStrengthBar from "react-password-strength-bar";

import * as constant from "../constants";
import Recaptcha from "react-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
let captchaRef = React.createRef();
class Signup extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      name: "",
      phone: "",
      email: "",
      password: "",
      message: "",
      submitted: "",
      fadeIn: false,
      captchaApproved: false,
    };
    this.validate = this.validate.bind(this);
    this.changeNameHandler = this.changeNameHandler.bind(this);
    this.changePhoneHandler = this.changePhoneHandler.bind(this);
    this.changePasswordHandler = this.changePasswordHandler.bind(this);
    this.changeEmailHandler = this.changeEmailHandler.bind(this);
   
    this.captchaCallback = this.captchaCallback.bind(this);
    this.verifyCaptchaCallback = this.verifyCaptchaCallback.bind(this);
  }

  changeNameHandler = (event) => {
    
    this.setState({ name:event.target.value });
  };

  changePhoneHandler = (event) => {
    this.setState({phone: event})
    var phoneno = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    if(event&&event!=""){
      if (phoneno.test(event)&&phoneno.test(event)==true) {
      
        this.setState({message:"",phone: event})
       
      
      }
      else {
        this.setState({message:"Please enter valid phone number",})
         
       }  
    }
   
   else{
      this.setState({message:""})
    }
  };

  changePasswordHandler = (event) => {
    if (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(event.target.value)
    ) {
      event.target.setCustomValidity("");
    } else {
      event.target.setCustomValidity(
        "Please enter at least 8 characters including upper, lower & special case characters"
      );
    }
    this.setState({ password: event.target.value });
  };

  changeEmailHandler = (event) => {
    this.setState({ email: event.target.value });
  };
  onCaptchaChange() {
    // captchaRef.current.remove();
    //captchaRef.current.reset();
  }

  captchaCallback() {
    //  captchaRef.current.reset();
    // captchaRef.current.render();
  }

  // specifying verify callback function
  verifyCaptchaCallback(response) {
    this.setState({ captchaApproved: true });
  }

  
  validate() {
    if (this.form.current.reportValidity()) {
      if (!this.state.captchaApproved) {
        const style = {
          fontSize: "150%",

          padding: "2%",
          marginTop: "90%",
          color: "white",
        };

        toast(
          <div class="container-fluid">
            <div class="row">Invalid Captcha!</div>
          </div>,

          { type: "error", position: "top-center", theme: "colored", style }
        );

        return false;
      }
     
      this.setState({ submitted: true });
      var phoneno = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      let user = {
        name: this.state.name,
        phone:phoneno.test(this.state.phone)==true?this.state.phone:"",
        email: this.state.email,
        password: this.state.password,
      };

      UserService.saveUser(user)
        .then((res) => { window.location = "/success";})
        .catch((res) => { this.setState({ submitted: "",});
          const style = {
            fontSize: "150%",

            padding: "2%",
            marginTop: "90%",
            color: "white",
          };

          toast(
            <div class="container-fluid">
              <div class="row">
                E-mail exists already! Please login instead!
              </div>
            </div>,

            { type: "error", position: "top-center", theme: "colored", style }
          );
         
        });
    }
  }

  
  render() {
    var modalEl = document.getElementById("myModal");
    if (modalEl) {
      modalEl.addEventListener("shown.bs.modal", function () {
        this.onCaptchaChange();
      });
      modalEl.addEventListener("hidden.bs.modal", function () {
        this.captchaCallback();
      });
    }
    
    return (
      <>
        
        <div
          className="agileits-register"
          onClick={() => this.setState({ fadeIn: false })}
          style={{
            float: "right",
            backgroundColor: "smoke",

            shapeOutside: "border-box",
            lineHeight: 0.1,
          }}
        >
          <p className="w3ls-login" style={{ marginTop: "-1%" }}>
            <h3>Sign-up Now!</h3>
            <div style={{ marginTop: "-1%" }}>
              <span style={{ color: "#df5519" }}> Already a member?</span>
              {""}
              <a
                style={{ marginLeft: "2%",fontSize:"large" }}
                href="#"
                data-toggle="modal"
                data-target="#myModal"
                onClick={this.props.login}
              >
                Login
              </a>
            </div>
          </p>

          <form
            ref={this.form}
            onSubmit={(e) => e.preventDefault()}
            style={{ lineHeight: 0.1 }}
          >
            <div className="w3_modal_body_grid w3_modal_body_grid1">
              <span>Name:</span>
              <input
                type="text"
                id="name"
                name="name"
                placeholder=" "
                onChange={this.changeNameHandler}
                required
              />
              
  
            </div>
            <div className="w3_modal_body_grid w3_modal_body_grid1">
              <span>Phone:</span>
             
              <PhoneInput
             
           
            required
               country="US"
               defaultCountry="US"
               name="name"
      placeholder="Phone number with country code"
      value={this.state.phone}
      onChange={(e)=>{this.changePhoneHandler(e)}}
      
      />
           
            </div>
            <p className="" style={{color:"red",marginLeft:"40%"}}>{this.state.message}</p> 
            <div className="w3_modal_body_grid">
              <span>Email:</span>
              <input
                required
                type="email"
                name="email"
                placeholder=" "
                onChange={this.changeEmailHandler}
              />
            </div>
            <div className="w3_modal_body_grid w3_modal_body_grid1">
              <span>Password:</span>
              <input
                required
                id="password"
                name="password"
                type="password"
                onChange={this.changePasswordHandler}
              />
            </div>
            <div className="w3_modal_body_grid">
              <span></span>
              <PasswordStrengthBar
                minLength={1}
                password={this.state.password}
              />
            </div>

            <div className="w3-agree" style={{ padding: "2%" }}>
              <input required type="checkbox" id="c1" name="cc" />
              <label className="agileits-agree" style={{ color: "#df5519" }}>
                I have read & agree to the{" "}
                <a href="/terms" style={{ color: "#df5519" }}>
                  Terms and Conditions
                </a>
              </label>
            </div>
            <div className="form-group signupCaptcha">
              <br />
              <div
                className="col-md-12 text-center"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Recaptcha
                  className="signupCaptcha"
                  ref={(e) => (captchaRef = e)}
                  sitekey={constant.CAPTCHA_SITE_KEY}
                  render="explicit"
                  onChange={this.onCaptchaChange}
                  verifyCallback={this.verifyCaptchaCallback}
                  onloadCallback={this.captchaCallback}
                />
              </div>
              <div className="clearfix"> </div>
            </div>

            <div className="text-center" style={{ marginTop: "-1.5%" }}>
              <input
                onClick={this.validate}
                type="submit"
                disabled={this.state.submitted}
                className="btn btn-primary"
                value="Sign up"
              />
            </div>
            <div className="clearfix"></div>
          </form>
        </div>
      </>
    );
  }
}
export default Signup;
