import React, { Component } from "react";
import InnerBanner from "./components/common/innerBanner";
import Breadcrumbs from "./components/common/breadcrumbs";

import ContactService from "./services/ContactService";
import Recaptcha from "react-recaptcha";
import * as constant from ".//constants";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Notify from "./services/Notify";
class Contact extends Component {
  constructor() {
    super();
    this.form = React.createRef();
    this.state = {
      name: "",
      email: "",
      phone:"",
      subject: "",
      message: "",
      submitted: false,
      captchaVerified: false,
      error:"",
    };
    this.validate = this.validate.bind(this);
   
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  validate =async()=> {
    
    if (this.form.current.reportValidity()) {
      if (this.state.captchaVerified !== true) {
      
        Notify.alert("Please make sure captcha has been verified!")
      
        return false;
      }
      this.setState({ submitted: true });
      var phoneno = /^\+(?:[0-9] ?){6,14}[0-9]$/;
      let data = {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
        phone:phoneno.test(this.state.phone)==true?this.state.phone:"",
      };
     let res=await ContactService.save(data)
     if(res&&res.data){
      Notify.alert(" Your message has been recorded successfully.")
      this.setState({ submitted: false });
      this.form.current.reset();
     }
     else{
      Notify.alert(" Something went wrong! Please try again!")
     }
       
    }
  }


  // specifying verify callback function
  verifyCallback(response) {
    this.setState({ captchaVerified: true });
  }
  changePhoneHandler = (event) => {
    this.setState({phone: event})
    var phoneno = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    if(event&&event!=""){
      if (phoneno.test(event)&&phoneno.test(event)==true) {
      
        this.setState({error:"",phone: event})
       
      
      }
      else {
        this.setState({error:"Please enter valid phone number",})
         
       }  
    }
   
   else{
      this.setState({error:""})
    }
  };

  render() {
    return (
      <>
    
        <div className=""><InnerBanner /></div>
        <div style={{marginTop:"-10%"}}>
        <Breadcrumbs title={["Contact Us"]} />
        <section className="privacy_policy_section">
          <div className="privacy_policy">
            <div className="container">
              <div className="row">
                <div className="col-md-8 animated fadeInLeft notransition">
                  <div className="col-md-2"></div>
                  <h1 className="smalltitle">
                    <span>Get in Touch</span>
                  </h1>
                  <br />

                  <form
                    id="ajax-contact-form"
                    ref={this.form}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="form-group">
                      <label for="personname" className="col-md-8 required">
                        Name
                      </label>
                      <div className="col-md-8">
                        <input
                          required
                          onBlur={(e) =>
                            this.setState({ name: e.target.value })
                          }
                          className="form-control"
                          type="text"
                          name="personname"
                          id="personname"
                          placeholder="Name"
                        />
                      </div>
                      {/* <div className="clearfix"> </div> */}
                    </div>
                    <div className="form-group">
                      <label for="personemail" className="col-md-8 required">
                        Email
                      </label>
                      <div className="col-md-8">
                        <input
                          onBlur={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          required
                          className="form-control"
                          type="email"
                          name="personemail"
                          id="personemail"
                          placeholder="E-mail"
                        />
                      </div>
                      {/* <div className="clearfix"> </div> */}
                      <div className="form-group">
                      <label for="personemail" className="col-md-8 required"style={{marginTop:"1%"}}>
                        Phone
                      </label>
                      <div className="col-md-8  w3_modal_body_grid w3_modal_body_grid1"style={{border:"",padding:"0px",margin:"0px"}}>
                      <PhoneInput
                      className="  col-md-12"
                      
                     autoComplete="off"
                      required
               country="US"
               defaultCountry="US"
               name="name"
      placeholder="Phone number with country code"
      value={this.state.phone}
      onChange={(e)=>{this.changePhoneHandler(e)}}
      
      />
      <p style={{color:"red"}}>{this.state.error}</p>
                      </div>
                      
                      </div>
                      {/* <div className="clearfix"> </div> */}
                    </div>
                    <div className="form-group">
                      <label for="contactsubject" className="col-md-8 required">
                        Subject
                      </label>
                      <div className="col-md-8">
                        <input
                          onBlur={(e) =>
                            this.setState({ subject: e.target.value })
                          }
                          required
                          className="form-control"
                          type="text"
                          name="contactsubject"
                          id="contactsubject"
                          placeholder="Subject"
                        />
                      </div>
                      {/* <div className="clearfix"> </div> */}
                    </div>
                    <div className="form-group">
                      <label for="contactsubject" className="col-md-8 required">
                        Message
                      </label>
                      <div className="col-md-8">
                        <textarea
                          onBlur={(e) =>
                            this.setState({ message: e.target.value })
                          }
                          required
                          className="form-control"
                          id="message"
                          name="message"
                          rows="7"
                          placeholder="Message"
                          style={{ resize: "none" }}
                        />
                      </div>
                      {/* <div className="clearfix"> </div> */}
                    </div>
                    <div className="form-group">
                      <br />
                      <div className="col-md-8">
                        <Recaptcha
                          sitekey={constant.CAPTCHA_SITE_KEY}
                          render="explicit"
                          verifyCallback={this.verifyCallback}
                          // onloadCallback={this.callback}  
                        />
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group">
                      <div className="col-md-8">
                        <input
                        
                          type="submit"
                          id="contactsubmit"
                          name="contactsubmit"
                          onClick={this.validate}
                          disabled={this.state.submitted}
                          className="btn btn-primary col-md-3"
                          value="Send"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-4 animated fadeInRight notransition">
                  <h1 className="smalltitle">
                    <span>Contact Us</span>
                  </h1>
                  <br />
                  <h4 className="font100">SBAT Matrimony</h4>
                  <ul className="unstyled wedoit"style={{marginTop:"10px" }}>
                    
                    <p>
                      <span style={{ marginRight: "5px",marginTop:"10px" }}>
                        <i className="icon-map-marker"></i>
                      </span>
                      Sri Bhaktha Anjaneya
4143
                    </p>
                    <p>
                      <span style={{ marginRight: "3px" }}>
                        <i className="icon-phone"></i>
                      </span>{" "}
                      Ayodhya way,
ljamsville, MD-21754.
                    </p>
                    <p>
                      <span style={{ marginRight: "3px" }}>
                        <i className="icon-phone"></i>
                      </span>{" "}
                      Phone: (301)-865-5555
                    </p>
                    <p>
                      <span style={{ marginRight: "2px" }}>
                        <i className="icon-envelope"></i>
                      </span>{" "}
                      Email: matrimony@sbat.org
                    </p>
                  </ul>
                  <div
                    className="br-hr type_short"
                    style={{ margin: "20px 0 20px" }}
                  >
                    <span className="br-hr-h">
                      <i className="icon-gear"></i>
                    </span>
                  </div>
                  
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
        
      </>
    );
  }
}

export default Contact;
