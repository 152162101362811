import React from "react";

function InnerBanner() {
  return (
    <>
    
      <div>
      <div className="w3layouts-inner-banner "style={{paddingTop:"30%"}}>
        <div className="container">
          <div className="clearfix"></div>
        </div>
      </div>
      </div>
    </>
  )
}

export default InnerBanner;
