import React, { Component } from "react";
import Login from "./components/login";
import Signup from "./components/signup";
import {  Image,notification} from "antd";
import SoulMateDiv from "./components/common/soulmateDiv";

import  ProfileService from './services/ProfileService'

import banner from './assets/images/matrimony-banner.png'

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
        active: "",
      show:true,
      visible: false,
    };
   
  }
  getActivateUser=async()=>{
    ProfileService. getActivateUsers().then((res)=>{
     this.setState({active:res.data.count})
     
    }).catch((err)=>{
    
    })
    
   }
    onClose = (e) => {
   
  };
   openNotification = () => {
    return(
      <div>{
        notification.open({
          
          description:<Image src={banner}/>,
            placement:"bottomLeft",
           
            
          onClick: () => {
            
          },
         
        })
        }
      
      </div>
    )
   
  };
  
  
  componentDidMount() {
   this.openNotification()
    
  }

  render() {
    
   
    
    return (
      <>
      
      
        <section id="Sign-Up">
        <div className="w3layouts-banner " id="home">
          <div className="container row" style={{marginLeft:"12%",}}>
            <div className="clearfix"></div>
            <div  className="col-md-6"style={{marginLeft:"200px"}}>
            
    
    </div>
           <div className="">
           {this.state.show ?<Login message={this.setMessage}register={()=>this.setState({show:false})} />:
           <Signup login={()=>this.setState({show:true})}/>}
           </div>
            
           
    
         
           </div>
        </div>
        </section>
        <section>
          
        </section>
        {/* <section>
          <div className="container-fluid">
          <div className="col-md-12">
        <img src={banner} className="col-md-12" />
      </div>
          </div>
        </section> */}
        <section>
        <SoulMateDiv  register={()=>this.setState({show:false})}/>
   
          </section>    
      </>
    );
  }
}
export default Home;
