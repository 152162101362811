import React, { Component } from "react";
import "antd/dist/antd.css";
import { Button, Space} from "antd";
import InterestService from "../../services/InterestService";
import AlertBox from "./Notification";
import Loding from "./spin";
import "react-toastify/dist/ReactToastify.css";
import Notify from "../../services/Notify";



class InterestBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      loadable: false,
      popupMsg: false,
      submitted: true,
      notsubmitted: false,
      userId: "",
      spin: false,
    };
  }
  
 
  onSubmit = async() => {
    let interestDetails = {
      toUserId: this.props.profileUserId,
      fromUserId: sessionStorage.getItem("userId"),
      fromProfileNumber: localStorage.getItem("profileNum"),
      fromEmail: sessionStorage.getItem("userEmail"),
      toProfileNumber: this.props.profileProfNum,
    };
this.setState({spin:true})
   let result=await InterestService.save(interestDetails)
     
        if (result) {
          this.setState({
            loadable: false,
            clicked: false,
            submitted: false,
            spin: true,
          });
          window.location.pathname = ("/profile/" + this.props.profileProfNum);
          Notify.alert("We've notified the user")
        }
        else{
          Notify.alert("We've  not notified the user")
         
          this.setState({
            loadable: false,
            clicked: false,
          });
        }
  };

  onSubmit1 = async() => {
    window.location.path = "/dashboard";
    this.setState({
      popupMsg: false,
    });
    let notinterest = {
      interestId: this.props.profileUserId,
      fromUser: sessionStorage.getItem("userId"),
    };
   let res=await InterestService.removeinterest(notinterest)
   if(res){
    this.setState({
              loadable: false,
              clicked: false,
              submitted: true,
              spin:false,
              submitted:true
            });
    
    Notify.alert("We've notified the user")
   
    window.location.pathname = ("/profile/" + this.props.profileProfNum)
   }
  
   };
  render() {
    return (
      <>
        

        <div style={{ display: "flex" }}>
          <Space>
            {this.state.submitted ? (
              <>
                <AlertBox
                  callback={this.onSubmit}
                  spin={this.state.spin&&<Loding />}
                  title="Show Interest"
                  button="Show Interest"
                  disabled={this.state.submitted}
                  content="You have expressed interest for this profile. An email will be sent to this profile. Do you want to continue ?"
                />
              </>
            ) : (
              <>
              <Space>
              <Button type="primary" disabled="true">
                  Interest Sent
                </Button>
              <AlertBox

                    callback={this.onSubmit}
                    spin={this.state.spin ? <Loding /> : ""}
                    title="Remove Interest"
                    button="Remove Interest"
                    content="Do you wish to Remove Interest for this profile ?"
                  />
              </Space>
                
              </>
            )}
          </Space>
        </div>
      </>
    );
  }
}

export default InterestBtn;
