import React, { useState } from "react";
import Header from "./components/common/header";
import Footer from "./components/common/footer";
import Home from "./home";
import Success from "./success";
import PaymentSuccess from "./paymentSuccess";
import PaymentFail from "./paymentFail";
import CommonLauncher from "./commonLauncher";
import Contact from "./contact";
import Profile from "./profile";
import ProfileEdit from "./profileEdit";
import LoginVerified from "./loginVerified";
import PrivacyPolicy from "./privacyPolicy";
import Payment from "./payment";
import Terms from "./terms";
import { Route, Switch,useLocation } from "react-router-dom";
import Checkout from "./components/checkout";
import PwLink from "./pwLink";
import Dashboard from "./dashboard";
import ResetPassword from "./components/common/resetPassword";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  
  
    return (
    <div>
 
      <Header/>
      
      <Switch>
      <Route exact path="/" render={(props) => <Home {...props} />} />
      
      <Route exact path="/success" component={Success} />
      <Route exact path="/paymentSuccess" component={PaymentSuccess} />
      <Route exact path="/paymentFail" component={PaymentFail} />
      
      <Route exact path="/pwLink" component={PwLink} />
      <Route exact path="/contact" component={Contact} />
      {/* <Route exact path="/commonLauncher" render={(props) => <CommonLauncher {...props} />}/> */}
      <Route exact path="/profile/:profileNumber?"render={(props) => <Profile {...props} />} />
     
      <Route exact path="/profileEdit" component={ProfileEdit} />
      <Route exact path="/payment" render={(props) => <Payment {...props} />} />
      <Route exact path="/checkout"render={(props) => <Checkout {...props} />}/>
      <Route exact path="/loginVerified" component={LoginVerified} />
      <Route exact path="/privacyPolicy" component={PrivacyPolicy} />

      <Route exact path="/terms" component={Terms} />

      
      <Route exact path="/dashboard" component={Dashboard}></Route>
      <Route exact path="/resetPassword/:encodedId" component={ResetPassword }/>
      <Route exact path="/user/activateUser/:encodedId" component={LoginVerified }/>
      
      <Route exact path="*" render={(props) => <Home {...props} />}/>
     
     </Switch>
      
      <Footer/>
      <ToastContainer />
    </div>
  );
}

export default App;
