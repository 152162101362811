

import React,{Component} from 'react'
import 'antd/dist/antd.css';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

let antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class Loding extends Component {
    constructor(){
        super()
    }
    render(){
        return(
            <div> <>
            <Spin style={{color: "white"}} indicator={antIcon} />
            </></div>
           
        )
    }
}
export default Loding
// ReactDOM.render(<Spin indicator={antIcon} />, document.getElementById('container'));