import React, { Component } from "react";
import TokenExpired from "./tokenExpired";
import "antd/dist/antd.css";
import logo from "../../assets/images/logo.png";
import "react-toastify/dist/ReactToastify.css";
import { Link,  } from "react-router-dom";
import ProfileService from "../../services/ProfileService";
import 'antd/dist/antd.css';
import Notify from "../../services/Notify";

class Header extends Component {
  constructor(props) {
    super();
    this.state = {
      logout: false,
      active: "",
    };
    this.logoutClick = this.logoutClick.bind(this);
    this.activeUser = this.activeUser.bind(this);
  }

  activeUser = async () => {
  let res=await  ProfileService.getActivateUsers()
  if(res&&res.data){
    this.setState({ active: res.data.count });
  }
  else{
    this.setState({ active:0  });
  }
      
  };
   
  
  logoutClick() {
    
    sessionStorage.clear();
    localStorage.clear();
    
    this.setState({ logout: true });
    Notify.alert("You have successfully logout")

    
   
  }
  componentDidMount() {
    this.activeUser();
    if (window.location.pathname === "/") {
      sessionStorage.clear();
      localStorage.clear();
      
    }
  }
  render() {
    let homePageHref = "/";

    localStorage.setItem("true", "true");
    if (sessionStorage.getItem("status") === "ACTIVE") {
      homePageHref = "/dashboard";
    } else if (sessionStorage.getItem("token") !== null) {
      homePageHref = "/profile";
    }

    return (
      <header>
       
        {this.state.logout ? <TokenExpired /> : <></>}
        <div
          className="navbar"
          style={{
            background: "#e2e9ef",
            paddingBottom: "6px",
            paddingTop: "6px",
          }}
        >
          <div className="navbar-inner">
            <div className="container "style={{float:"left"}}>
              <a className="brand" href="https://sbat.org/">
                <img src={logo} alt="" width="200" height="60" />
              </a>
            </div>
          </div>
        </div>

        <div
          className="navbar navbar-inverse-blue navbar"
          style={{ backgroundColor: "#401A0A" }}
        >
          <div className="navbar-inner">
            <div className="container">
              <span
                style={{ color: "white", fontSize: "larger", lineHeight: 3 ,marginLeft:"-5%"}}
              >
                ACTIVE PROFILES : {this.state.active}
              </span>

              <div className="pull-right">
                <nav className="navbar nav_bottom" role="navigation">
                  <div className="navbar-header nav_2">
                    <button
                      type="button"
                      className="navbar-toggle collapsed navbar-toggle1"
                      data-toggle="collapse"
                      data-target="#bs-megadropdown-tabs"
                    >
                      Menu
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>
                  <div
                    className="collapse navbar-collapse"
                    id="bs-megadropdown-tabs"
                  >
                    <ul className="nav navbar-nav nav_1">
                      <li>
                        <Link
                          to={
                            sessionStorage.getItem("status") == "ACTIVE" &&
                            sessionStorage.getItem("profileStatus") == "ACTIVE"
                              ? "/dashboard"
                              : "/"
                          }
                          style={{ fontSize: "large" }}
                        >
                          HOME
                        </Link>
                      </li>

                      {(sessionStorage.getItem("profileStatus") == "ACTIVE" &&
                        sessionStorage.getItem("status") == "ACTIVE") ||
                      sessionStorage.getItem("profileStatus") == "WITHDRAWN" ? (
                        <li>
                          <a
                            className=" button"
                            href="/profile"
                            style={{ fontSize: "large" }}
                          >
                            MY PROFILE
                          </a>
                        </li>
                      ) : (
                        <></>
                      )}
                      {sessionStorage.getItem("token") != null ? (
                        <></>
                      ) : (
                        <>
                          <li className="last">
                            <Link
                              className=" button"
                              to="/contact"
                              style={{ fontSize: "large" }}
                            >
                              CONTACT
                            </Link>
                          </li>
                        </>
                      )}

                      {sessionStorage.getItem("token") && (
                        <li
                          style={{
                            display:
                              sessionStorage.getItem("token") !== null
                                ? "block"
                                : "none",
                          }}
                          className="last"
                        >
                          {/* <a
                            onClick={this.logoutClick}
                            style={{ color: "white", fontSize: "large" }}
                          >
                            LOGOUT
                          </a> */}
                          <Link
                            className=" button"
                            to=""
                            style={{ fontSize: "large" }}
                            onClick={this.logoutClick}
                          >
                            {" "}
                            LOGOUT
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="clearfix"> </div>
            </div>
          </div>
        </div>
       
      </header>
    );
  }
}
export default Header;
