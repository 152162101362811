import axios from "axios";
import * as constant from "../constants";

class ProfileService {
  updateProfile(profileDetails) {
    return axios.post(
      constant.PROFILE_API_URL + "/save",
      profileDetails,
      constant.config
    );
  }

  getById(userId) {
    return axios.get(
      constant.PROFILE_API_URL + "/getById?id=" + userId,
      constant.config
    );
  }
  getById1(userId,config) {
    return axios.get(
      constant.PROFILE_API_URL + "/getById?id=" + userId,
      config
    );
  }
  getByProfileNumber(profileNumber,userId) {
    return axios.get(
      constant.PROFILE_API_URL +
        "/getByProfileNumber?profileNumber=" +
        profileNumber+"&userId="+userId,
      constant.config
    );
  }
  avatar(formData) {
    return axios.post(
      constant.PROFILE_API_URL + "/avatar",
      formData, 
      constant.config
    );
  }

  uploadPhotos(formData) {
    return axios.post(
      constant.PROFILE_API_URL + "/photos",
      formData,
      constant.config
    );
  }

  deletePhoto(formData) {
    return axios.post(
      constant.PROFILE_API_URL + "/deletePhoto",
      formData,
      constant.config
    );
  }
  profileDump(data) {
    return axios.post(
      constant.PROFILE_API_URL + "/saveProfileDump",
      data,
      constant.config
    );
  }
  getProfileDumpData(userId) {
    return axios.get( 
      constant.PROFILE_API_URL + "/getProfileDumpData?id="+ userId,
     
      constant.config
    );
  }
  getActivateUsers(){
    return axios.get(
      constant.USER_API_URL + "/getActivateUsers"
      
     
    );
  }
  withdrawn(withdraw) {
    return axios.post( 
      constant.PROFILE_API_URL + "/withdrawn",
      withdraw,
     
      constant.config
    );
  }
  
}

export default new ProfileService();
