import React from "react";

import { Link } from "react-router-dom";

function SoulMateDiv() {
  return (
    <>
      <div
        className="w3l_find-soulmate text-center  "
        style={{ backgroundColor: "#E5D2B0", paddingBottom: "1%" }}
      >
        <h3
          style={{
            letterSpacing: "2px",
            color: "darkorange",
          }}
        >
          Find Your Soulmate
        </h3>
        <div className="container " >
       <div className="col-md-12 cards">
       <div
              className="col-md-4 w3_soulgrid"
              style={{  backgroundColor: "darkorange", }}
            >
          <Link className="scroll" to="/">
           
              <i
                className="fa fa-pencil-square-o"
                style={{ color: "white" }}
                aria-hidden="true"
              ></i>
              <h3 style={{ color: "white" }}>Sign Up</h3>
              <p style={{ color: "white" }}>upload your profile</p>
            
          </Link>
          </div>
          
          <div
              className="col-md-4 w3_soulgrid"
              style={{
                
                background: "#e2e9ef",
                color: "darkorange",
              }}
            >
          <Link
            className="scroll"
            style={{ color: "darkorange" }}
            to="/"
          >
           
              <i
                className="fa fa-search"
                style={{ color: "darkorange" }}
                aria-hidden="true"
              ></i>
              <h3 style={{ color: "darkorange" }}>Search</h3>
              <p style={{ color: "darkorange" }}>your right partner</p>
            
          </Link>
          </div>
         
          <div
              className="col-md-4 w3_soulgrid"
              style={{  background: "#401A0A" }}
            >
         <Link className="scroll" to="/">
           
              <i
                className="fa fa-users"
                style={{ color: "white" }}
                aria-hidden="true"
              ></i>
              <h3 style={{ color: "white" }}>Connect</h3>
              <p style={{ color: "white" }}>with your perfect match</p>
           
          </Link>
         </div>
       </div>
        </div>
      </div>
    </>
  );
}

export default SoulMateDiv;
