import React, { Component } from "react";
import Login from "./components/login";
import InnerBanner from "./components/common/innerBanner";
import SoulMateDiv from "./components/common/soulmateDiv";
import { Link } from "react-router-dom";
import LoginService from "./services/LoginService"
class LoginVerified extends Component {
  constructor(props) {
    super(props);
    this.state = {
      encodedId: this.props.match.params.encodedId,
    };
   this.activeUserLink=this.activeUserLink.bind(this)
  }
  activeUserLink=async()=>{
    let encodedId={
      encodedId:this.state.encodedId
    }
    let res=await LoginService.activeUser(encodedId)
   
   console.log(res,"res")
    
   }
   componentDidMount(){
    // console.log(this.state.encodedId,"encodedId")
    this.activeUserLink()
   }
  render() {
    return (
      <>
        <InnerBanner />
        <div className="w3l_about"style={{marginTop:"-10%"}}>
          <div className="container">
            <h2>Sign-up Success!</h2>
            <br />
            <h5>You're all set!</h5>
            <p className="w3ls-login pull-left">
              Please{" "}
              <Link to="/" data-toggle="modal" data-target="#myModal">
                Login
              </Link>{" "}
              Now!
            </p>
          </div>
        </div>
       
        <SoulMateDiv />
      </>
    );
  }
}

export default LoginVerified;
