
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import axios from "axios";

// import "./assets/css/bootstrap.css"
// import "./assets/css/style.css"
// import "./assets/css/menu.css"
// import "./assets/css/font-awesome.css"
// import "./assets/css/easy-responsive-tabs.css"
// import "./assets/fonts/fontawesome-webfont.svg"
// import "./assets/fonts/glyphicons-halflings-regular.svg"

axios.interceptors.response.use(undefined, function (error) {
  if (error.toString().indexOf("401") !== -1) {
    sessionStorage.removeItem("token");
  } else {
  }
  return Promise.reject(error);
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
