import React, { Component } from "react";
import InnerBanner from "./components/common/innerBanner";
import ListingService from "./services/ListingService";
import TokenExpired from "./components/common/tokenExpired";
import { UserOutlined , HeartFilled } from "@ant-design/icons";

import { ToastContainer, toast } from "react-toastify";
import "antd/dist/antd.css";

import {Card,Typography,Space,Tag,Tooltip,Avatar,Select,Checkbox,Row, Col, Input,Button, Switch} from "antd";
import * as constant from "./constants";
import InterestService from "./services/InterestService";

import { Link, NavLink } from "react-router-dom";

const { CheckableTag } = Tag;
const { Meta } = Card;

const tagsData = [
  "Age Group",
  "Height",
  "Marital Status",
  "Caste",
  "Gotra",
  "Place Of Birth",
];
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      profileList: null,
      profileList2: null,

      searchBoxOptions: [],
      selectedProfileName: "",
      listUpdateCount: 0,
      selectedTags: [],
      searchValue: "",
      refreshByTags: true,
      refreshByName: true,
      interestArray: [],
      checked: true,
      disabled: false,
      label: false,
      Profiledata: "",
      show: false,
      interestArray: "",
      getListBytags: "",
      desiredAgeGroup: "",
      desiredMaritalStatus: "",
      desiredCaste: "",
      desiredAgeGroup: "",
      desiredHeight: "",
      desiredGotra: "",
      desiredPlaceOfBirth: "",
      firstName: "",
      tagsSearch: "",
      profileDump: "",
      agefilter:[],
      maritalfilter:[],
      castefilter:[],
      castevalue:[],
      gotrafilter:[],
      country:[],
      countryfilter:[],
      showprofile:"",
      gotraList:[],
      ageList:[],
    };
    
    sessionStorage.setItem("flag", 0);
    this.sliceIntoChunks = this.sliceIntoChunks.bind(this);
    this.getInterests = this.getInterests.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.GetCasteList=this.GetCasteList.bind(this)
    this.options = props.options;
    this.showProfile=this.showProfile.bind(this)
    this.fetchData=this.fetchData.bind(this)
    this.fetchData1=this.fetchData1.bind(this);
    this.Getagelist=this.Getagelist.bind(this)
  }
ageFilter=(e)=>{
  this.setState({searchBoxOptions:e})
  this.setState({agefilter:e})
  

}
MaritalStatusFilter=(e)=>{
  this.setState({searchBoxOptions:e})
  
  this.setState({maritalfilter:e})

}
casteFilter=(e)=>{
  this.setState({searchBoxOptions:e})
  this.setState({castevalue:e})
}
gotraFilter=(e)=>{
  
  this.setState({searchBoxOptions:e})
  this.setState({gotrafilter:e})
}
countryFilter=(e)=>{
  this.setState({searchBoxOptions:e})
  this.setState({countryfilter:e})
}
showProfile=(e)=>{
  
  this.setState({showprofile:e})
  if(e&&e=="Interested Profiles"){
    this.fetchData1()
   }
   if(e&&e=="Show all Profiles"){
    
    this.fetchData()
   }
  
}
redirect=()=>{
  
  if(this.state.countryfilter !=""||this.state.gotrafilter!=""||this.state.castevalue!=""||this.state.maritalfilter!=""||this.state.agefilter!="")
 
  window.location.pathname=("/dashboard")
}
  changeHandle = () => {
    this.setState({
      label: !this.state.label,
      show: !this.state.show,
      checked: !this.state.checked,
      disabled: this.state.disabled,
    });
    if (this.state.label) {
      this.fetchData();
    } else {
      this.fetchData1();
    }
  };

   fetchData=async(id = sessionStorage.getItem("userId"),ischeck,tagDetails = null,profileName = "") =>{
    
   let res= await ListingService.fetchProfilesForUser(
      id,
      (ischeck = this.state.label ? "&ischeck=1" : "&ischeck=1"),
      tagDetails,
      profileName
    )
    if(res&&res.data){
       this.setState({ profileList: res.data, profileList2: res.data });
    }
    else{
      this.setState({ profileList: [] });
    }
    
  }

  fetchData1 = async(  id = sessionStorage.getItem("userId"),
  ischeck = this.state.label ? "&ischeck=0" : "&ischeck=0",
  tagDetails = null,
  profileName = "" ) =>{
  let res= await ListingService.fetchProfilesForUser( 
    id,ischeck,
    tagDetails, profileName)
     
        if( res.data){
          this.setState({ profileList: res.data });
        }
        else{
          const style = {
            fontSize: "150%",

            padding: "2%",
            marginTop: "90%",
            color: "white",
          };
          toast(
            <div class="">
              <i class=""></i>No profiles were found for your desired
              preferences.
            </div>,
            { type: "error", position: "top-center", theme: "colored", style }
          );
          this.setState({ profileList: [] });
        }
      
       
      
  }

   getInterests=async(id = sessionStorage.getItem("userId"))=> {
    try {
      let res = await InterestService.getById(id);
      this.setState({ interestArray: res.data.interest });

      return res.data;
    } catch (e) {
      return e;
    }
  }
  sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }
  GetCasteList = async () => {
    try {
      let res = await ListingService.getCasteList();
let data=[""];
      if (res.data) {
      data.push(res.data.message)
       this.setState({castefilter:data} );
        
        
      }
     
     

     
    } catch (error) {
      return error;
    }
  };
  GetCountriesList = async () => {
    try {
      let res = await ListingService.getCountriesList();

      if (res.data) {
        this.setState({ country: res.data.message });
      }
    } catch (error) {
      return error;
    }
  };
  GetGotralist = async () => {
    try {
      let res = await ListingService.getGotraList();

let gotra=[""]
      if (res.data) {
        gotra.push(res.data.message)
        this.setState({ gotraList: gotra });
      }
      
    } catch (error) {
      return error;
    }
  };
  Getagelist = async () => {
    try {
      let res = await ListingService.getAgeList();

let age=[""]
      if (res.data) {
        age.push(res.data.message)
        this.setState({ ageList: age });
      }
      
    } catch (error) {
      return error;
    }
  };
  componentDidMount() {
   this.GetGotralist()
       this.GetCasteList()
     this.GetCountriesList()
     this.Getagelist()
    
     this.fetchData()
    if (localStorage.getItem("profileInactiveDays") && localStorage.getItem("profileInactiveDays") <= 15) {
      const style = {
        fontSize: "20px",
        padding: "2%",
        marginTop: "70%",
        backgroundColor: "darkorange",
        color: "white",
      };

      toast(
        <div class="container-fluid">
          <div class="row">
            {" "}
            Your profile is going to Expire within 15 days !{" "}
          </div>
        </div>,

        { position: "top-center", style,hideProgressBar:true }
      );
      localStorage.removeItem("profileInactiveDays");
    }

    window.scrollTo(0, 0);

    if (sessionStorage.getItem("status") == "ACTIVE") {
      // this.fetchData();
      this.getInterests();
    }

    
    this.setState({ profileList: this.state.getListBytags });
  }
 
  

  componentDidUpdate(prevProps, prevState) {
   
    if (
      this.state.selectedProfileName !== prevState.selectedProfileName &&
      this.state.refreshByName
    ) {
    }
    if (
      this.state.selectedTags !== prevState.selectedTags &&
      this.state.refreshByTags
    ) {
      this.getListByTags();
      this.setState({
        searchValue: "",
        selectedProfileName: this.state.profileList,
        refreshByName: false,
      });
    }
  }

  handleTagChange(tag, checked) {
    if (localStorage.getItem("profileGender") !== "") {
    }

    if (!this.state.refreshByTags) {
      this.setState({
        refreshByTags: true,
      });
    }
    const { selectedTags } = this.state;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags?.filter((t) => t !== tag);
    this.setState({ selectedTags: nextSelectedTags });
   
  }

  render() {
    const { Text } = Typography;
    let optionsArray = [];
    let cardArray = [];
let Option=Select
    let searchSpace;
    let { listUpdateCount, interestArray } = this.state;
    const { selectedTags } = this.state;


    if ( this.state.profileList &&this.state.profileList.length !=0&&this.state.profileList.message != "Profile Not found") {
     
      this.state.profileList.filter((profile) => {
       
          if (this.state.searchBoxOptions == "") {
           
            return profile;
          } 
        else  if (profile.firstName .toLowerCase().includes(this.state.searchBoxOptions.toLowerCase()) +profile.lastName.toLowerCase()
              .includes(this.state.searchBoxOptions.toLowerCase())) {
            return profile;
          } 
          else  if (
            this.state.searchBoxOptions === profile.firstName + " " + profile.lastName
          ) {
            return profile;
          }
          
         
          else if (profile.desiredAgeGroup.toLowerCase().includes(this.state.searchBoxOptions.toLowerCase()) )
              {
            return profile;
          } 
     
          else if (
            this.state.searchBoxOptions ===profile.desiredAgeGroup
           
          ) {
            return profile;
          }
          else if (profile.desiredMaritalStatus.toLowerCase().includes(this.state.searchBoxOptions.toLowerCase()) )
              {
            return profile;
          } 
     
          else  if (
            this.state.searchBoxOptions ===profile.desiredMaritalStatus
           
          ) {
            return profile;
          }
          else   if (profile.desiredCaste.toLowerCase().includes(this.state.searchBoxOptions.toLowerCase()) )
          {
        return profile;
      } 
 
      else if (
        this.state.searchBoxOptions ===profile.desiredCaste
       
      ) {
        return profile;
      }
      else   if (profile.desiredGotra.toLowerCase().includes(this.state.searchBoxOptions.toLowerCase()) )
      {
    return profile;
  } 

  else if (
    this.state.searchBoxOptions ===profile.desiredGotra
   
  ) {
    return profile;
  }
  else  if (profile.desiredPlaceOfBirth.toLowerCase().includes(this.state.searchBoxOptions.toLowerCase()) )
  {
return profile;
} 

else if (
this.state.searchBoxOptions ===profile.desiredPlaceOfBirth

) {
return profile;
}
        })?.map((profile, i) =>{

          
            cardArray.push(
              <>
                <Col direction="horizontal" align="center">
                  <div
                    className="col-md-4 "
                    style={{ backgroundColor: "#E5D2BF", }}
                  >
                    <Card 
                     
                      key={i}
                      cover={
                        <Avatar
                        
                          src={constant.AVATAR_PATH + profile.avatarFileName}
                          onMouseDown={(e) => e.preventDefault()}
                          onContextMenu={(e) => e.preventDefault()}
                          size={210}
                          style={{ marginTop:"2%",borderRadius:"2%",}}
                          icon={<div style={{marginTop:"-40px"}}><UserOutlined style={{fontSize:"100px",}} />
  <Text style={{fontSize:"18px",position:"absolute",top:"50px",left:"30px",color:'white',textAlign:"center"}} >Photo not uploaded</Text>
                          </div>}
                          shape="square"
                        ></Avatar>
                      
                      }
                      style={{
                        marginTop: "3%",
  
                        
                        color: "#401A0A",
                        
                        height:"420px"
                      }}
                      className=""
                    >
                      <div style={{ marginTop: "-5%", padding:"1%"}}>
                        <Meta
                          title={[
                            profile.firstName + " " + profile.lastName + " ",
                            interestArray?.includes(profile.userId) ? (
                              <Tooltip title={"Interest Sent"}>
                                <HeartFilled
                                  style={{
                                    fontSize: "16px",
                                    
                                    color: "#FF0000",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <> </>
                            ),
                          ]}
                        
                        />
                      </div>
  
                      <ul
                        className="profile_item"
                        style={{ backgroundColor: "#E5D2BF",}}
                      >
                        <Tooltip title={"Go to Profile"}>
                          <Link to={"/profile/" + profile.profileNumber}>
                           
                            <li
                              className="profile_item-desc "
                              style={{ textAlign: "justify" }}
                            >
                              <div className="row">
                                <Row
                                  className=" col-sm-12"
                                  style={{ display: "flex" }}
                                >
                                  <p
                                    className=" "
                                    style={{
                                      color: "#401A0A",
                                      fontSize: "14px",
                                      paddingLeft: "4%",
                                    }}
                                  >
                                    {"Age : " + profile.age} <br />
                                    {"Desired Age group : " + profile.desiredAgeGroup} <br />
                                    {"Height : " + profile.height_feet +" "+
                                     
                                      "ft"+" "+profile.height_inches +" "+"in"}
                                    <br />
                                    {"Education : " +
                                      profile.highestEducation}{" "}
                                    <br />
                                    {"Place Of Birth : " +
                                      profile.PlaceOfBirth}{" "}
                                    {/* +
                                      {profile.profileNumber} */}
                                    <br />
                                  </p>
                                </Row>
                              </div>
                            </li>
                            <div className="clearfix"> </div>
                          </Link>
                        </Tooltip>
                      </ul>
                    </Card>
                    <br />
                  </div>
                </Col>
  
                
              </>
            );
           
         
         
         
        })

      sessionStorage.setItem("profileName", optionsArray[0]);
      if (listUpdateCount === 0) {
        this.options = optionsArray;
      }
      let chunkedArray = this.sliceIntoChunks(cardArray, 5);

      searchSpace = (
        <>
          {chunkedArray?.map( (profile, i)=> {
            
              return (
                <>
  
                  <div key={i}>{profile}</div>
  
                  <br />
                </>
              );
            
           
           
          })}
        </>
      );
    } else {
      
      searchSpace = (
        <h5 type="secondary" style={{ color: "red", textAlign: "center" }}>
          No Profiles to Show
        </h5>
      );
    }
    sessionStorage.setItem("profileName", JSON.stringify(optionsArray));

    const onChange = (data) => {
      this.setState({
        searchBoxOptions: data.target.value.trim(),
       
      });

      this.setState({
        searchValue: data,
      });
      if (data === "" || data === undefined) {
        this.setState({
          selectedProfileName: data,
          refreshByName: true,
          listUpdateCount: 0,
          searchBoxOptions: [],
          refreshByTags: true,
          selectedTags: [
            "Age Group",
            "Height",
            "Marital Status",
            "Caste",
            "Gotra",
            "Place Of Birth",
          ],
        });
      }
    };

    return (
      <>
     
        <TokenExpired />

        <InnerBanner />
        <div className="dashboard-bg top-3" >
        <div className="container" >
        
        <div className="col-md-12"style={{paddingTop:"2%",}}>
<div className="col-md-6">
<Input
                     
                     type="text"
                     placeholder="Search Profile Names ...."
                    
                     name="search"
                     onChange={onChange}
                   ></Input>
</div>
<div className="col-md-4">

                    {this.state.label ? (<Space><label 
                          style={{
                            color: "#f50",
                           
                          }}
                        >
                          Interested Profiles
                        </label>
                      <Switch
                        checked={this.state.checked}
                        onClick={this.changeHandle}
                      >
                        
                      </Switch>
                      </Space>
                    ) : (
                      <Space>
                       <label style={{ color: "#f50" }}>
                          Show all Profiles
                        </label>
                      <Switch
                        checked={this.state.checked}
                        onClick={this.changeHandle}
                      >
                       
                      </Switch>
                      </Space>
                    )}
                 
                          
</div>
<div className="col-md-2">
  <Button type="primary"onClick={()=>this.redirect()}>Clear filters</Button>
</div>
        </div>
        </div>
        <Row>
      <Col span={24} > 
      <div className="container">
      <div className="col-md-12 col-sm-6">
                        <div className="col-md-3">
                          <Select
                          placeholder="Desired age group"
                            style={{ width: "100%" }}
                            onChange={(e) =>this.ageFilter(e) }
                            required
                            
                            value={this.state.agefilter}
                            id="desiredAgeGroup"
                          >
                             {this.state.ageList[1]&&this.state.ageList.length!=0&&this.state.ageList[1]?.map((e,i)=>{
return(<Option value={e.agegroupname}>{e.agegroupname}</Option>)
                            })}
                          
                          </Select>
                          
                        </div>
                        <div className="col-md-2">
                        <Select
                           
                           
                            style={{ width: "100%" }}
                            placeholder="Marital status"
                            onChange={(e) =>this.MaritalStatusFilter(e) }
                           value={this.state.maritalfilter}
                          >
                            
                            <Option key="Single">Single</Option>
                            <Option key="Widowed">Widowed</Option>
                            <Option key="Divorced">Divorced</Option>
                          </Select>
                         
                        </div>
                        <div className="col-md-2">
                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e)=>this.gotraFilter(e)}
                            placeholder="Gotra"
                            value={this.state.gotrafilter}
                           
                           
                          >
                            {this.state.gotraList[1]&&this.state.gotraList.length!=0&&this.state.gotraList[1]?.map((e,i)=>{
return(<Option value={e.ghotraname}>{e.ghotraname}</Option>)
                            })}
                            
                          </Select>
                        </div>
                        
                          <div className="col-md-2">
                        <Select
                            
                           
                            style={{ width: "100%" }}
                            placeholder="Caste"
                            onChange={(e) =>this.casteFilter(e) }
                           value={this.state.castevalue}
                          >
                            {/* {this.state.castefilter&&this.state.castefilter.length !=0&&this.state.castefilter[1].map((e,i)=>
                               (
                             
                               <Option value={ e.castename}>{ e.castename}</Option>
                             

                               
                                
                              )
                            )} */}
                           <Option value="Brahmine">Brahmin</Option>
                           <Option value="Kshathriya">Kshathriya</Option>
                           <Option value="vaishya">vaishya</Option>
                           <Option value="shudra">shudra</Option>
                          
                            
                          </Select>
                          </div>
                          <div className="col-md-3">
                          <Select
                           
                            style={{ width: "100%" }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            value={this.state.countryfilter}
                           
                           placeholder="Place of birth"
                            onChange={(e)=>this.countryFilter(e)}
                          >
                            {this.state.country &&
                              this.state.country.length &&
                              this.state.country?.map((e, i) => {
                                return (
                                  <>
                                    <Option value={e.country_name}>
                                      {e.country_name}
                                    </Option>
                                  </>
                                );
                              })}
                          </Select>
                          </div>
                         
                      </div>
        </div> 
     </Col>
    </Row>
   
    
    
    <div className="container">
    <div className="row">
                {searchSpace}
              </div>
    </div>
    
     <div style={{padding:"5%"}}></div> 
                      
        {/* <div
          className="w3ls-list col-md-12 col-sm-12"
          style={{
            marginTop: "-10%",
            backgroundColor: "#E5D2BF",
            
          }}
        >
          <div className="container">
            <div className="col-md-12 profiles-list-agileits">
              <div className="row" style={{ marginTop: "-5%" }}>
                <div
                  className="col-xs-12"
                  style={{ display: "flex", justifyContent: "center",marginLeft:"10%", marginTop: "4%", }}
                >
                  <Space direction="horizontal" align="center">
                    <div className="col-md-12">

<Input
                      style={{
                        width: "300px ",
                        border: "red",
                        boxSizing: "border-box",
                        padding: "6px",
                        border: "1px solid gray",
                      
                        boxShadow: "1px,1px,2px #333333",
                        fontSize: "16px",
                        color: "black",
                        
                      }}
                      type="text"
                      placeholder="Search Profile Names ...."
                      
                      name="search"
                      onChange={onChange}
                    ></Input>

                    </div>
                   
                     <div className="col-md-12">
                          <Row direction="horizontal" className="col-md-12" align="center">
                    {this.state.label ? (
                      <Checkbox
                        checked={this.state.checked}
                        onClick={this.changeHandle}
                      >
                        <label 
                          style={{
                            color: "#f50",
                            // color:"#401A0A"
                          }}
                        >
                          Interested Profiles
                        </label>
                      </Checkbox>
                    ) : (
                      <Checkbox
                        checked={this.state.checked}
                        onClick={this.changeHandle}
                      >
                        <label style={{ color: "#f50" }}>
                          Show all Profiles
                        </label>
                      </Checkbox>
                    )}
                  </Row>
                          </div>
                  </Space>
                </div>
              </div>
              <br />
              <br />

              <div className="col-xs-12">
                <Row direction="horizontal" align="center">
                  {/* <Tooltip title={"Age Group is mandatory"}>
                    <Tag color="#f50" className="">
                      Age Group
                    </Tag>
                  </Tooltip> */}
                  
                
                      {/* {tagsData.map((tag, i) => (
                    <Tooltip title={"Check/Uncheck " + tag}>
                      <CheckableTag
                        style={{
                          // color:"darkorange",
                          color: "#401A0A",
                          fontSize: "14px",
                        }}
                        key={i}
                        checked={selectedTags.indexOf(tag) > -1}
                        onChange={(checked) =>
                          this.handleTagChange(tag, checked)
                        }
                      >
                        {tag}
                      </CheckableTag>
                    </Tooltip>
                  ))} */}
                 
                {/* </Row>
              </div>

              <br />
              <br />

              <div className="row" style={{ marginTop: "-5%" }}>
                {searchSpace}
              </div>
            </div>
            <div className="clearfix"> </div>
          </div>
        </div> */}
         
    </div>
      </>
    );
  }
}

export default Dashboard;
