import React, { Component } from "react";
import "antd/dist/antd.css";
import {  Button, Space ,notification,message} from "antd";
import InterestService from "../../services/InterestService";



import AlertBox from "./Notification";
import Loding from "./spin";
import Notify from "../../services/Notify";

class NotInterestBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      loadable: false,
      popupMsg: false,
      submitted: false,
      notsubmitted: false,
      userId: "",
      spin: false,
    };
   
  }
  
  onSubmit = async() => {
  
    this.setState({
      popupMsg: false,
    });
    let notinterest = {
      interestId: this.props.profileUserId,
      fromUser: sessionStorage.getItem("userId"),
    };
   let res=await InterestService.removeinterest(notinterest)
   if(res){
    this.setState({ loadable: false,clicked: false, submitted: true, spin:false,});
          
    Notify.alert("We've notified the user")
    window.location.pathname = ("/profile/" + this.props.profileProfNum)
   }
  
   };

 
    
 
  render() {
    return (
      <>
       
        
          <div style={{ display: "flex" }}>
            <Space>
              
              {!this.state.submitted ? (
                <>
                  {" "}
                  <AlertBox
                    callback={this.onSubmit}
                    spin={this.state.spin ? <Loding /> : ""}
                    title="Remove Interest"
                    button="Remove Interest"
                    content="Do you wish to Remove Interest for this profile ?"
                  />
                </>
              ) : (
                <>
                  <Button type="primary" disabled={this.state.submitted}>
                    Interest Removed
                  </Button>
                </>
              )}
            </Space>
          </div>
       </>
    );
  }
}

export default NotInterestBtn;
