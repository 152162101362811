import React from "react";
import { Link } from "react-router-dom";


function Footer() {
  return (
    <footer>
     
      <div className="copy-right" style={{ backgroundColor: "#401A0A" }}>
        <div className="container">
          <p>
            &emsp;|&emsp;
            <Link
              to={(sessionStorage.getItem("profileStatus") == "ACTIVE") && (sessionStorage.getItem("status") == "ACTIVE")
                  ? "/dashboard"
                  : "/"
              }
            >
              {" "}
              Home
            </Link>
            &emsp;|&emsp;
            <Link to="/terms"> Terms</Link>&emsp;|&emsp;
            <Link to="/privacyPolicy"> Privacy</Link>
            &emsp;|&emsp;
            <Link to="/contact"> Contact Us</Link>&emsp;|&emsp;
          </p>
        </div>
        <div className="container">
          <p>
            © {new Date().getFullYear()} All rights reserved | Maintained by{" "}
            <a href="https://sbatsevas.org"> SBAT</a> | Powered By{" "}
            <a href="https://tribandtech.com">Lionorbit</a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
