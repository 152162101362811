import React, { Component } from "react";
import InnerBanner from "./components/common/innerBanner";
import Breadcrumbs from "./components/common/breadcrumbs";
import ProfileService from "./services/ProfileService";
import TokenExpired from "./components/common/tokenExpired";
import "antd/dist/antd.css";
import {
  Avatar,
  Button,
  message,
  Space,
  Col,
  
  Image,
  Select,
 
} from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import * as constant from ".//constants";
import InterestBtn from "./components/common/interestBtn";

import InterestService from "./services/InterestService";
import NotinterestBtn from "./components/common/notInterestBtn";


import PaymentService from "./services/PaymentService";
import AlertBox from "./components/common/Notification";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "antd/lib/input/TextArea";
import "antd/dist/antd.css";
import { Menu, Dropdown, Popconfirm } from "antd";
import LoginService from "./services/LoginService";
import Moment from "react-moment";
import Notify from "./services/Notify";
let { Option } = Select;

class Profile extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.state = {
      profileImage: "",
      profileUserId: "",
      profileId: "",
      horoscopeImage: "",
      photo1Image: "",
      photo2Image: "",
      profileFor: "",
      profileNumber: "NA",
      firstName: "",
      lastName: "",
      gender: "",
      height: "",
      dob:"",
      maritalStatus: "",
      motherName: "",
      motherAge: "",
      motherOccupation: "",
      fatherName: "",
      fatherAge: "",
      fatherOccupation: "",
      familyIncome: "",
      familyValues: "",
      city: "",
      statee: "",
      highestEducation: "",
      ugDegree: "",
      ugCollege: "",
      pgDegree: "",
      pgCollege: "",
      occupation: "",
      income: "",
      desiredAgeGroup: "",
      desiredHeight: "",
      desiredMaritalStatus: "",
      message: "",
      submitted: "",
      redirect: null,
      btnDisplay: "none",
      profileUserIdionStatus: "INACTIVE",
      isViewerSelf: true,
      interestBtnClick: false,
      interestBtnLoadable: false,
      popupMsg: false,
      caste: "",
      gotra: "",
      nakshatra: "",
      rasi: "",
      desiredCaste: "",
      desiredGotra: "",
      desiredGender: "",
      interestArray: [],
      PlaceOfBirth: "",
      desiredPlaceOfBirth: "",
      ProfileInactiveDays: "",
      story: "",
      profileDump: "",
      menu: "",
      story: "",
      marriageFixed: "",
      checkBox: false,
      Foundmatchelsewhere: "Found a match elsewhere.",
      otherReason: "Other Reasons",
      postponing: "Postponing marriage plans",
      preferNot: "Prefer not to say",
      email:"",
      oldpassword:"",
      newpassword:"",
      confirmpassword:"",
      error1:"",
      error2:"",
      error3:"",
      phone:""
    };
sessionStorage.setItem("preprofile",window.location.pathname)
    this.Payment = this.Payment.bind(this);
    this.state.menu = (
      <Menu>
        <Menu.Item key="0">
          <AlertBox
            callback={this.onTextArea1}
            button="Found a match Through SBAT MAtrimony"
            title="Found a match Through SBAT MAtrimony"
            content1="Would you like to share your story with us?"
            queation=" I agree to share my user story on the SBAT Matrimony website ?"
            checkBoxbelow="Thanks for using SBAT MAtrimony. You are withdrawing the profile and once withdrawn the profile will be deleted permanently"
            checkbox={<input type="checkbox" onChange={this.checkBox} />}
            comment={
              <TextArea
                rows={6}
                onChange={(e)=>{this.setState({story: e.target.value})}}
                placeholder="Please share your story..."
              ></TextArea>
            }
          />
        </Menu.Item>
        <Menu.Item key="1">
          <AlertBox
            callback={this.onTextArea2}
            button="Found a match elsewhere."
           
            title="Found a match elsewhere."
            content="ONCE WITHDRAWN THE PROFILE WILL BE PERMANENTLY DELETED!"
            queation="Do you surely want to withdraw?"
          />
        </Menu.Item>
        <Menu.Item key="2">
          <AlertBox
            callback={this.onTextArea4}
            button="Postponing marriage plans"
            
            title="Postponing marriage plans"
            content="ONCE WITHDRAWN THE PROFILE WILL BE PERMANENTLY DELETED!"
            queation="Do you surely want to withdraw?"
          />
        </Menu.Item>
        <Menu.Item key="3">
          <AlertBox
            callback={this.onTextArea5}
            button="Prefer not to say"
            
            title="Prefer not to say"
            content="ONCE WITHDRAWN THE PROFILE WILL BE PERMANENTLY DELETED!"
            queation="Do you surely want to withdraw?"
          />
        </Menu.Item>

        <Menu.Item key="4">
          <AlertBox
            callback={this.onTextArea3}
            button="Other Reasons"
            title="Other Reasons"
            content="ONCE WITHDRAWN THE PROFILE WILL BE PERMANENTLY DELETED!"
            queation="Do you surely want to withdraw?"
          />
        </Menu.Item>
      </Menu>
    );
  }
  checkBox = (e) => {
    this.setState({ checkBox: !this.state.checkBox });
  };
  onTextArea1 = () => {
    
    this.setState({
     
      marriageFixed: "Found a match Through SBAT MAtrimony",
    });
    let withdraw = {
      userId: sessionStorage.getItem("userId"),
      reason: this.state.marriageFixed,

      userStory: this.state.story,
      agreedToShare: this.state.checkBox,
    };
  
    this.withdrawnProfile(withdraw);
  };

  onTextArea2 = (e) => {
    let withdraw = {
      userId: sessionStorage.getItem("userId"),
      reason: this.state.Foundmatchelsewhere,

      userStory: this.state.Foundmatchelsewhere,
      agreedToShare: true,
    };
    this.withdrawnProfile(withdraw);
  };
  onTextArea3 = (e) => {
    let withdraw = {
      userId: sessionStorage.getItem("userId"),
      reason: this.state.otherReason,

      userStory: this.state.otherReason,
      agreedToShare: true,
    };
    this.withdrawnProfile(withdraw);
  };
  onTextArea4 = (e) => {
    let withdraw = {
      userId: sessionStorage.getItem("userId"),
      reason: this.state.postponing,

      userStory: this.state.postponing,
      agreedToShare: true,
    };
    this.withdrawnProfile(withdraw);
  };
  onTextArea5 = (e) => {
    this.setState({ preferNot: "Prefer not to say" });
    let withdraw = {
      userId: sessionStorage.getItem("userId"),
      reason: this.state.preferNot,

      userStory: this.state.preferNot,
      agreedToShare: true,
    };
    this.withdrawnProfile(withdraw);
  };

  async Payment() {
    let paymentId = this.props.match.params.profileNumber;
    PaymentService.getById(paymentId)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {});
  }
  myFunction(e) {
    var x = document.getElementById("fname");
    x.value = e.target.value.toUpperCase();
  }
  onInputValueChange(e) {
    const name = e.target.name;
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {}
    );
  }
  onClick = () => {
    window.location.pathname = "/profileEdit";
  };
  changePasswordHandler = (event) => {
    if (
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        event.target.value
      )
    ) {
      event.target.setCustomValidity("");
    } else {
      event.target.setCustomValidity(
        "Please enter at least 8 characters including upper, lower & special case characters"
      );
    }
    this.setState({ password: event.target.value });
  };
  ChangePassword=async()=>{
    document.getElementById("myForm").reset();
    
   let cpassword={
     userid:sessionStorage.getItem("userId"),
      oldpassword:this.state.oldpassword,
      newpassword:this.state.newpassword
    }
    
     if((this.state.oldpassword)&&(this.state.newpassword)&&(this.state.confirmpassword) !== ""&&(this.state.newpassword == this.state.confirmpassword)){
      let res = await LoginService.changePassword(cpassword)
     
        if(res.data.response ==="failed"){
          this.setState({error1:res.data.message})
        //  window.location.pathname=("/profile")
         
        } else if(res.data&&res.data.response ==="success"){
          this.setState({oldpassword:''})
          this.setState({newpassword:''})
          this.setState({confirmpassword:''})
          this.setState({error1:""})
         
          Notify.alert("Password has been updated successfully..!!")
         
          return res.data.response
        
        
        }
      
      
    }
    else{
     
      if(this.state.oldpassword == ""){ this.setState({error1:"Please enter the old password"}) 
      return false;}
      if(this.state.newpassword== ""){  this.setState({error1:"Please enter the new password"})
      return false;}
     
      if(this.state.newpassword !== this.state.confirmpassword){ this.setState({error1:" The Confirm password does not match with the new password"})
      return false;}
      
     
      
    }
   
  }

  withdrawnProfile = async (withdraw) => {
    
   let res=await ProfileService.withdrawn(withdraw)
     
        if (res.data&&res.data.response ==="success") {
         
          
      Notify.alert("Withdraw successfull")
        
          sessionStorage.clear();
          localStorage.clear();
          window.location.pathname = "/";
          return res.data.response
          
        }
     
     
  };
  async fetchData() {
    if (this.props.match.params.profileNumber) {
      const profileNumber = this.props.match.params.profileNumber;
      const userId=sessionStorage.getItem("userId")
      return ProfileService.getByProfileNumber(profileNumber,userId)
        .then((result) => {
          if(result.data.response !=undefined&&result.data.response=="failed"){
          
            window.location.pathname=("/dashboard")
          }
          if (result.data.profile.userId === sessionStorage.getItem("userId")) {
            this.setState({ isViewerSelf: true });
          } else {
            this.setState({ isViewerSelf: false });
          }
          return result.data;
        })
        .catch((error) => {
          if (error.toString().indexOf("401") === -1) {
            window.location = "/commonLauncher";
          }
        });
    } else {
      return ProfileService.getById(sessionStorage.getItem("userId")).then(
        (result) => {
          this.setState({ profileDump: result.data.profile });
          return result.data;
        }
      );
    }
  }

  async getInterests(
    id = sessionStorage.getItem("userId"),
    toUser = this.state.profileUserId
  ) {
    return InterestService.getById(id, toUser).then((result) => {
      this.setState({ interestArray: result.data.interest });

      return result.data;
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getInterests();
    // if(this.props.location.pathname !== window.location.pathname){
    // window.location.pathname=("/commonLauncher")
    // }
    if (localStorage.getItem("profileMsg") === "activate") {
      message.warn("Please activate your profile to view this page!");
      localStorage.removeItem("profileMsg");
    }
    if (localStorage.getItem("profileMsg") === "uneditable") {
      localStorage.removeItem("profileMsg");
    }
    if (localStorage.getItem("redirectedFromCheckout")) {
      sessionStorage.removeItem("token");
      localStorage.setItem("transactionExpired", true);
      window.location.pathname = "/";
    }
    if (
      sessionStorage.getItem("userId") === this.state.profileUserId &&
      localStorage.getItem("ProfileInactiveDays") <= 15 &&
      this.state.activationStatus == "ACTIVE"
    ) {
      window.location.pathname = "/profile";
    }
    if (
      this.props.match.params.profileNumber &&
      sessionStorage.getItem("status") === "INACTIVE"
    ) {
      localStorage.setItem("profileMsg", "activate");
      // window.location.pathname = "/profile";
    }
    this.fetchData().then((data) => {
      if (data&&data?.artifact) {
        this.setState({
          profileImage: constant.AVATAR_PATH + data?.artifact.avatarFileName,
          horoscopeImage:
            constant.HOROSCOPE_PATH + data.artifact.horoscopeFileName,
          btnDisplay: data.artifact.horoscopeFileName === "" ? "none" : "block",
          photo1Image: constant.PHOTO1_PATH + data.artifact.photo1FileName,
          photo2Image: constant.PHOTO2_PATH + data.artifact.photo2FileName,
          profileNumber: data.artifact.userId,
        });
      }
      if (data&&data.profile) {
        var profile = data.profile;
localStorage.getItem("profileNumber",data.profile.profileNumber)
        if (this.state.isViewerSelf) {
          localStorage.setItem("desiredHeight", profile.desiredHeight);
          localStorage.setItem("desiredAgeGroup", profile.desiredAgeGroup);

          localStorage.setItem(
            "desiredMaritalStatus",
            profile.desiredMaritalStatus
          );
          localStorage.setItem("desiredCaste", profile.desiredCaste);
          localStorage.setItem("desiredGotra", profile.desiredGotra);
          localStorage.setItem("desiredGender", profile.desiredGender);
          // localStorage.setItem(
          //   "ProfileInactiveDays",
          //   profile.ProfileInactiveDays
          // );
          // localStorage.setItem(
          //   "ProfileInactiveDays",
          //   profile.ProfileInactiveDays
          // );
        }
        this.setState({
          profileNumber:
            profile.profileNumber === "" ? "NA" : profile.profileNumber,
          ProfileInactiveDays:
            profile.ProfileInactiveDays === ""
              ? "NA"
              : profile.ProfileInactiveDays,
          profileFor:
            profile.profileFor === "" ? "NA" : "My " + profile.profileFor,
          firstName:
            profile.firstName === "" ? "<First Name>" : profile.firstName,
          lastName: profile.lastName === "" ? "<Last Name>" : profile.lastName,
          gender: profile.gender === "" ? "<Gender>" : profile.gender,
          dob: profile.dob === "" ? "<Date of Birth>" : profile.dob,
          height:
            profile.height === "" ? "<Height>" : profile.height_feet+" " + "ft"+" "+profile.height_inches+" "+"in",
          maritalStatus:
            profile.maritalStatus === ""
              ? "<Marital Status>"
              : profile.maritalStatus,
          motherName: profile.motherName === "" ? "<Name>" : profile.motherName,
          motherAge: profile.motherAge === "" ? "" : profile.motherAge,
          motherOccupation:
            profile.motherOccupation === "" ? "" : profile.motherOccupation,
          fatherName: profile.fatherName === "" ? "<Name>" : profile.fatherName,
          fatherAge: profile.fatherAge === "" ? "" : profile.fatherAge,
          fatherOccupation:
            profile.fatherOccupation === "" ? "" : profile.fatherOccupation,
          familyIncome:
            profile.familyIncome === ""
              ? "<Family Income>"
              : profile.familyIncome,
          familyValues:
            profile.familyValues === ""
              ? "<Family Values"
              : profile.familyValues,
          city: profile.city === "" ? "<City>" : profile.city,
          statee: profile.state === "" ? "<State>" : profile.state,
          highestEducation:
            profile.highestEducation === ""
              ? "<Highest Education>"
              : profile.highestEducation,
          ugDegree: profile.ugDegree === "" ? "<UG Degree>" : profile.ugDegree,
          ugCollege:
            profile.ugCollege === "" ? "<UG College>" : profile.ugCollege,
          pgDegree: profile.pgDegree === "" ? "<PG Degree>" : profile.pgDegree,
          pgCollege:
            profile.pgCollege === "" ? "<PG College>" : profile.pgCollege,
          occupation:
            profile.occupation === "" ? "<Occupation>" : profile.occupation,
          income: profile.income === "" ? "<Income>" : profile.income,
          desiredAgeGroup:
            profile.desiredAgeGroup === "" ? "NA" : profile.desiredAgeGroup,
          desiredHeight:
            profile.desiredHeight === ""
              ? "NA"
              : profile.desiredHeight + " inches",
          desiredMaritalStatus:
            profile.desiredMaritalStatus === ""
              ? "NA"
              : profile.desiredMaritalStatus,
          activationStatus: profile.status === "" ? "INACTIVE" : profile.status,
          caste: profile.caste === "" ? "<Caste>" : profile.caste,
          gotra: profile.gotra === "" ? "<Gotra>" : profile.gotra,
          rasi: profile.rasi === "" ? "<Rasi>" : profile.rasi,
          nakshatra:
            profile.nakshatra === "" ? "<Nakshatra>" : profile.nakshatra,
          desiredCaste:
            profile.desiredCaste === "" ? "NA" : profile.desiredCaste,
          desiredGotra:
            profile.desiredGotra === "" ? "NA" : profile.desiredGotra,

          desiredGender:
            profile.desiredGender === ""
              ? "<Profilegender>"
              : profile.desiredGender,
          PlaceOfBirth:
            profile.PlaceOfBirth === "" ? "NA" : profile.PlaceOfBirth,
          desiredPlaceOfBirth:
            profile.desiredPlaceOfBirth === ""? "NA": profile.desiredPlaceOfBirth,

          profileId: profile.profileId,
          profileUserId: profile.userId,
          phone:profile.phone,
        });
      }
    });
  }

  render() {
    let stateValue;
    switch (this.state.statee) {
      case "AL":
        stateValue = "Alabama";
        break;
      case "AK":
        stateValue = "Alaska";
        break;
      case "AZ":
        stateValue = "Arizona";
        break;
      case "AR":
        stateValue = "Arkansas";
        break;
      case "CA":
        stateValue = "California";
        break;
      case "CO":
        stateValue = "Colorado";
        break;
      case "CT":
        stateValue = "Connecticut";
        break;
      case "DE":
        stateValue = "Delaware";
        break;
      case "DC":
        stateValue = "District Of Columbia";
        break;
      case "FL":
        stateValue = "Florida";
        break;
      case "GA":
        stateValue = "Georgia";
        break;
      case "HI":
        stateValue = "Hawaii";
        break;
      case "ID":
        stateValue = "Idaho";
        break;
      case "IL":
        stateValue = "Illinois";
        break;
      case "IN":
        stateValue = "Indiana";
        break;
      case "IA":
        stateValue = "Iowa";
        break;
      case "KS":
        stateValue = "Kansas";
        break;
      case "KY":
        stateValue = "Kentucky";
        break;
      case "LA":
        stateValue = "Louisiana";
        break;
      case "ME":
        stateValue = "Maine";
        break;
      case "MD":
        stateValue = "Maryland";
        break;
      case "MA":
        stateValue = "Massachusetts";
        break;
      case "MI":
        stateValue = "Michigan";
        break;
      case "MN":
        stateValue = "Minnesota";
        break;
      case "MS":
        stateValue = "Mississippi";
        break;
      case "MO":
        stateValue = "Missouri";
        break;
      case "MT":
        stateValue = "Montana";
        break;
      case "NE":
        stateValue = "Nebraska";
        break;
      case "NV":
        stateValue = "Nevada";
        break;
      case "NH":
        stateValue = "New Hampshire";
        break;
      case "NJ":
        stateValue = "New Jersey";
        break;
      case "NM":
        stateValue = "New Mexico";
        break;
      case "NY":
        stateValue = "New York";
        break;
      case "NC":
        stateValue = "North Carolina";
        break;
      case "ND":
        stateValue = "North Dakota";
        break;
      case "OH":
        stateValue = "Ohio";
        break;
      case "OK":
        stateValue = "Oklahoma";
        break;
      case "OR":
        stateValue = "Oregon";
        break;
      case "PA":
        stateValue = "Pennsylvania";
        break;
      case "RI":
        stateValue = "Rhode Island";
        break;
      case "SC":
        stateValue = "South Carolina";
        break;
      case "SD":
        stateValue = "South Dakota";
        break;
      case "TN":
        stateValue = "Tennessee";
        break;
      case "TX":
        stateValue = "Texas";
        break;
      case "UT":
        stateValue = "Utah";
        break;
      case "VT":
        stateValue = "Vermont";
        break;
      case "VA":
        stateValue = "Virginia";
        break;
      case "WA":
        stateValue = "Washington";
        break;
      case "WV":
        stateValue = "West Virginia";
        break;
      case "WI":
        stateValue = "Wisconsin";
        break;
      case "WY":
        stateValue = "Wyoming";
        break;
      default:
        stateValue = "<State>";
        break;
    }

    return (
      <>
        <TokenExpired />

        <InnerBanner />
        {/* <ToastContainer hideProgressBar={true} limit="1" /> */}

        <div style={{ marginTop: "-10%" }}>
          <div>
            {sessionStorage.getItem("status") == "ACTIVE" ? (
              <Breadcrumbs title={["Profile"]} />
            ) : (
              <></>
            )}
          </div>
          <div className="w3ls-list " style={{ backgroundColor: "#E5D2BF" }}>
            <div className="container ">
              <h2 style={{ color: "#401A0A" }}>Profile{this.props.address}</h2>
              <div
                className="container col-md-9   profiles-list-agileits "
                style={{ float: "revert" }}
              >
                <div className=" col single_w3_profile">
                  <div className="agileits_profile_image">
                    <div>
                      <Avatar
                        size={200}
                        icon={<UserOutlined />}
                        src={this.state.profileImage}
                        onMouseDown={(e) => e.preventDefault()}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <div className="w3layouts_details">
                    {sessionStorage.getItem("status") &&
                    sessionStorage.getItem("status") == "ACTIVE" &&
                    sessionStorage.getItem("profileStatus") &&
                    sessionStorage.getItem("profileStatus") == "ACTIVE" ? (
                      <div>
                        <h4 style={{ color: "#00A800" }}>
                          Profile ID : <b>{this.state.profileNumber}</b>
                          (Active)
                        </h4>
                        <span
                          style={{
                            // color: "#FF0000"
                            color: "darkorange",
                            fontSize: "20px",
                          }}
                        >
                          {sessionStorage.getItem("userId") ===
                          this.state.profileUserId
                            ? "Profile Inactive Days :"
                            : ""}{" "}
                          {sessionStorage.getItem("userId") ===
                          this.state.profileUserId
                            ? this.state.ProfileInactiveDays
                            : ""}{" "}
                        </span>
                      </div>
                    ) : (
                      <h4 style={{ color: "#ff4c4c" }}>
                        Profile ID : <b>{this.state.profileNumber}</b> (Not
                        Activated)
                      </h4>
                    )}
                    <div className="row">
                      <div className="col-sm-5">
                        <p>
                          {this.state.firstName + " " + this.state.lastName}
                          <br />
                          {this.state.dob !=""&&(<Moment format="DD-MM-YYYY">{this.state.dob}</Moment>)}
                       
                          <br />
                          {this.state.occupation}
                          <br />
                          {this.state.income}
                        </p>
                      </div>
                      <div
                        className="col-sm-6 pull-left"
                        style={{ textAlign: "center" }}
                      >
                        <br />

                        <Space>
                          {" "}
                          <Image
                              height={120}
                              width={120}
                            size={100}
                            icon={<UserOutlined />}
                            src={this.state.photo1Image}
                            onMouseDown={(e) => e.preventDefault()}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                          <Image
                           height={120}
                           width={120}
                            className=""
                            shape="square"
                            size={100}
                            icon={<UserOutlined />}
                            src={this.state.photo2Image}
                            onMouseDown={(e) => e.preventDefault()}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </Space>
                      </div>
                    </div>
                    <br />
                    
                    <div className="row">
                      <div className="col-sm-3 col-md-4">
                        {this.state.isViewerSelf ? (
                          <Button
                            type="primary"
                            disabled={
                              sessionStorage.getItem("status") == "ACTIVE" &&
                              sessionStorage.getItem("profileStatus") ==
                                "ACTIVE" &&
                              localStorage.getItem("profileInactiveDays") <= 15
                            }
                            onClick={(e) =>
                              (window.location.pathname = "/profileEdit")
                            }
                          >
                            {sessionStorage.getItem("status") &&
                            sessionStorage.getItem("status") == "INACTIVE"
                              ? "Update & Pay"
                              : "Edit Profile"}
                          </Button>
                        ) : this.state.interestArray?.includes(
                            this.state.profileUserId
                          ) ? (
                          <Col>
                            <Space>
                              <Button type="primary" disabled="true">
                                Interest Sent
                              </Button>

                              <NotinterestBtn
                                status={this.state.activationStatus}
                                profileId={this.state.profileId}
                                profileProfNum={this.state.profileNumber}
                                profileUserId={this.state.profileUserId}
                              />
                            </Space>
                          </Col>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <Space>
                              <InterestBtn
                                status={this.state.activationStatus}
                                profileUserId={this.state.profileUserId}
                                profileProfNum={this.state.profileNumber}
                                profileId={this.state.profileId}
                              />{" "}
                            </Space>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                      <Col>
                            <Space>
                            {sessionStorage.getItem("userId") ===
                          this.state.profileUserId &&
                        localStorage.getItem("profileInactiveDays") <= 15 &&
                        sessionStorage.getItem("status") == "ACTIVE" &&
                        sessionStorage.getItem("profileStatus") == "ACTIVE" && (
                          <AlertBox
                            callback={this.onClick}
                            title="Renew Profile"
                            content="Renewal Charges $100 per year. Would you like to continue ? "
                            button="Renew Profile"
                           
                          />
                        ) }
                        {sessionStorage.getItem("userId")&&sessionStorage.getItem("userId") ==
                          this.state.profileUserId&&(
<AlertBox
                            callback={this.ChangePassword}
                            title="Change Password"
                            refresh={"/profile"}
                            password={<div>
                              <p className="text-red"style={{color:"red",fontSize:"16px"}}>{this.state.error1}</p>
                              <br />
                              <form id="myForm">
                             

                              <div><input type="password"placeholder="Enter Old Password"autocomplete="off" value={this.state.oldpassword} required className="form-control "onChange={(e)=>this.setState({oldpassword:e.target.value})}></input></div><br />
                              <div><input type="password"placeholder="Enter New Password"autocomplete="off"value={this.state.newpassword}required className="form-control "onChange={(e)=>this.setState({newpassword:e.target.value})}></input></div><br />
                              <div><input type="password"placeholder="Confirm New Password"autocomplete="off"value={this.state.confirmpassword} required className="form-control "onChange={(e)=>this.setState({confirmpassword:e.target.value})}></input></div><br />
                              <div>
                             
                                
                                </div>
                            
                              </form>
                             </div>}
                            button="Change Password "
                          />)}
                            </Space>
                          </Col>
                       
                        {/* <Button
                        type="primary"
                        style={{ display: this.state.btnDisplay }}
                        onClick={(e) =>
                          (window.location.href = this.state.horoscopeImage)
                        }
                      >
                        {<LinkOutlined />}
                        Horoscope
                      </Button> */}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="clearfix"></div>
                </div>
                <div
                  className="profile_w3layouts_details"
                  style={{ paddingLeft: "8%" }}
                >
                  <div className="agileits_aboutme">
                    <h5>Brief Details:</h5>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        {" "}
                        Full Name:{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-10" style={{marginTop:"1%"}}>
                          {this.state.firstName + " " + this.state.lastName}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        {" "}
                        Gender:{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.gender}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        {" "}
                        Date of Birth:{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.dob!=""&&(<Moment format="DD-MM-YYYY">{this.state.dob}</Moment>)} </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        {" "}
                        Height (Feet/Inch):{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.height}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        {" "}
                        Marital Status:{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5" style={{marginTop:"1%"}}>
                          {this.state.maritalStatus}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        {" "}
                        Place Of Birth:{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.PlaceOfBirth}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        {" "}
                        Phone Number:{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.phone}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <h5>Family Details:</h5>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Mother :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-10" style={{marginTop:"1%"}}>
                         
                          {this.state.motherName !=""&&this.state.motherName+", "} 
                           {this.state.motherAge !=""&&this.state.motherAge+", "}  
                           {this.state.motherOccupation !=""&&this.state.motherOccupation }
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Father :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-10" style={{marginTop:"1%"}}>
                            {this.state.fatherName !=""&&this.state.fatherName+", "} 
                           {this.state.fatherAge !=""&&this.state.fatherAge+", "}  
                           {this.state.fatherOccupation !=""&&this.state.fatherOccupation }
                        
                          {/* {this.state.fatherName +
                            ", " +
                            this.state.fatherAge +
                            ", " +
                            this.state.fatherOccupation} */}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Family Income (in USD):{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5">
                          {this.state.familyIncome}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        City/State :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-10" style={{marginTop:"1%"}}>
                        {this.state.city !=""&&this.state.city+", "} 
                        {this.state.statee !=""&&this.state.statee+", "} 

                          {/* {this.state.city +
                            ", " +
                            (this.state.statee != null ? stateValue : null)} */}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Family Values :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.familyValues}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <h5>Education Details:</h5>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Highest Level of Education :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.highestEducation}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Bachelor's Degree :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.ugDegree}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        School/College :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.ugCollege}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Graduate Degree :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.pgDegree}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Graduate School :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.pgCollege}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <h5>Employment Details:</h5>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Occupation :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.occupation}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Annual Income (in USD):{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.income}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <h5>Optional Details:</h5>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Caste :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.caste}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">Gotra: </label>
                      <div className="col-md-8" >
                        {" "}
                        <div className="col-md-5"style={{marginTop:"1%"}}>{this.state.gotra}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">Rasi : </label>
                      <div className="col-md-8" >
                        <div className="col-md-11">{this.state.rasi}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Nakshatra :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-11">{this.state.nakshatra}</div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <h5>Desired Partner Preferences:</h5>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">Age : </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.desiredAgeGroup}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Height (in inches) :{" "}
                      </label>
                      <div className="col-md-8" >
                        {" "}
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.desiredHeight}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Marital Status :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-11">
                          {this.state.desiredMaritalStatus}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Caste :{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.desiredCaste}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        Gotra :{" "}
                      </label>
                      <div className="col-md-8" >
                        {" "}
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.desiredGotra}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">Gender:</label>
                      <div className="col-md-8" >
                        {" "}
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.desiredGender}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    <div className="form-group profileFields">
                      <label className="col-sm-3 col-md-4 control-label1">
                        {" "}
                        Desired Place Of Birth:{" "}
                      </label>
                      <div className="col-md-8" >
                        <div className="col-md-5"style={{marginTop:"1%"}}>
                          {this.state.desiredPlaceOfBirth}
                        </div>
                      </div>
                      <div className="clearfix"> </div>
                    </div>
                    
                    <div className="row" >
                      <div className="col-md-6"style={{ marginTop: "2%",  }}>
                        {sessionStorage.getItem("userId") ==
                        this.state.profileUserId ? (
                          <Button
                            type="primary"
                            disabled={
                              sessionStorage.getItem("status") == "ACTIVE" &&
                              sessionStorage.getItem("profileStatus") ==
                                "ACTIVE" &&
                              localStorage.getItem("profileInactiveDays") <= 15
                            }
                            onClick={(e) =>
                              (window.location.pathname = "/profileEdit")
                            }
                          >
                            {sessionStorage.getItem("status") === "INACTIVE"
                              ? "Edit Profile"
                              : "Edit profile"}
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>

                      {/* <div className="clearfix"> </div> */}
                      {sessionStorage.getItem("profileStatus") === "ACTIVE" &&
                      sessionStorage.getItem("status") === "ACTIVE" &&
                      sessionStorage.getItem("userId") ==
                        this.state.profileUserId && (
                        <div 
                          style={{
                            // backgroundColor: "#E5D2BF",
                            padding: "3%"
                           
                          }}
                          className="pull-right"
                        >
                          <Dropdown
                            className="btn btn-warning active pull-right"
                            overlay={this.state.menu}
                            trigger={["click"]}
                          >
                            <a
                              className="ant-dropdown-link "
                              onClick={(e) => e.preventDefault(e)}
                            >
                              Withdraw Profile <DownOutlined />
                            </a>
                          </Dropdown>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"> </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Profile;
