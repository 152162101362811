import axios from "axios";
import * as constant from "../constants";

class ListingService {
  fetchProfilesForUser(id,ischeck,tagDetails,profileName) {
    var endPoint = "";

    if (tagDetails===null) {
      if (profileName === "") {
       
        endPoint = "/getById?id=" + id + ischeck;
      } else {
        
        endPoint = "/getByIdAndProfileName?id=" + id + "&name=" + profileName;
         
      }
      return axios.get(constant.LISTING_API_URL + endPoint, constant.config);
    } else {
      
      return axios.post(
        constant.LISTING_API_URL + "/getListByTagsRevised",
        tagDetails,
        constant.config
      ); 
    }
  }
  getCountriesList(){
    return axios.get(constant.LISTING_API_URL+"/getCountriesList", constant.config)
  }
  getCasteList(){
    return axios.get(constant.LISTING_API_URL+"/getCasteList", constant.config)
  }
  getGotraList(){
    return axios.get(constant.LISTING_API_URL+"/getGhotraListing", constant.config)
  }
  getAgeList(){
    return axios.get(constant.LISTING_API_URL+"/getAgeGroupList", constant.config)
  }
  checkDesirable(id, viewer) {
    return axios.get(
      constant.LISTING_API_URL +
        "/checkDesirable?id=" +
        id +
        "&viewer=" +
        viewer,
      constant.config
    );
  }
  notinterested(id, viewer) {
    return axios.post(
      constant.LISTING_API_URL +"/notinterest",
      {
        id, viewer
       
      },

      constant.config
    );
  }
 
}


export default new ListingService();
